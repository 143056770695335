import type { InlineBookmarkPluginElement } from '../types';
import { InlineTemplateElement } from '../../common/inlineTemplate';
import { EMPTY } from 'config/constants';
import { forwardRef } from 'react';

type InlineBookmarkElementProps = {
  selected?: boolean;
  element: InlineBookmarkPluginElement;
  children: React.ReactNode;
  handleClick?: () => void;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
  variant?: string;
};

export const InlineBookmarkElement = forwardRef<
  InlineBookmarkElementProps,
  InlineBookmarkElementProps & HTMLElement
>(({ selected, element, handleClick, attributes = EMPTY.OBJECT, ...rest }, ref) => {
  return (
    <InlineTemplateElement
      // @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardedRef<InlineBookmarkElementProps>' is not assignable to type 'LegacyRef<HTMLElement>'.
      ref={ref}
      {...rest}
      {...attributes}
      selected={selected}
      testID="inlineBookmark"
      data-selected={selected}
      element={element}
      handleClick={handleClick}
    />
  );
});
InlineBookmarkElement.displayName = 'InlineBookmarkElement';
