import styled from 'styled-components';
import { Grid, Stack } from 'common/ui/Layout';
import { Icon } from 'common/ui/Icon';
import { Button } from 'common/ui/Button';
import { Colors } from 'styles';
import Dialog from 'common/ui/Dialog';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import { SysChkValidator } from './validators';
import type { SysChkValidatorTypes } from './validators';
import sizes from 'styles/sizes';
import type { ValidationResult } from './useSystemCheck';
import { CHROME_EXTENSION_INSTALL_PAGE } from 'config/constants';
import spacings from 'styles/spacings';
import { useMemo } from 'react';
import Tooltip from 'common/ui/Tooltip';

const ICON_COL_WIDTH = `${spacings.large}rem`;
const H_PADDING = `${spacings.small}rem`;
const BYPASS_TOOLTIP_CONTENT =
  'Bypassing the system check will allow you to continue without resolving the issues. Please proceed with caution.';

const Container = styled(DialogContentText)`
  font-size: ${sizes.textBody1};
  margin-bottom: 0;

  a {
    color: ${Colors.blue5};
  }
`;

const getSysCheckStyleAttr = (
  isSystemValid: boolean,
  shouldBypassSystemCheck: boolean,
  isValidationInProgress: boolean
) => {
  if (isValidationInProgress) {
    return {
      systemCheckText: 'Checking system...',
      systemCheckIcon: 'refresh',
      systemCheckIconColor: Colors.gray7,
      systemCheckBackground: Colors.gray3,
    };
  } else if (shouldBypassSystemCheck && isSystemValid) {
    return {
      systemCheckText: 'System check passed and bypassed',
      systemCheckIcon: 'error_outline',
      systemCheckIconColor: Colors.orange6,
      systemCheckBackground: Colors.orange1,
    };
  } else if (shouldBypassSystemCheck && !isSystemValid) {
    return {
      systemCheckText: 'System check failed but bypassed',
      systemCheckIcon: 'error_outline',
      systemCheckIconColor: Colors.orange6,
      systemCheckBackground: Colors.orange1,
    };
  } else if (isSystemValid) {
    return {
      systemCheckText: 'System check passed!',
      systemCheckIcon: 'check_circle',
      systemCheckIconColor: Colors.green4,
      systemCheckBackground: Colors.green2,
    };
  }

  return {
    systemCheckText: 'System check failed',
    systemCheckIcon: 'error',
    systemCheckIconColor: Colors.red5,
    systemCheckBackground: Colors.red1,
  };
};

const RefreshNotice = () => (
  <div
    css={`
      text-align: center;
      padding: 0 2rem 2rem;
      font-size: 14px;
    `}
  >
    If you've recently enabled or installed the extension, you must refresh this window to get
    accurate results.
  </div>
);

export type Props = {
  isSystemValid: boolean;
  validationResults: Array<ValidationResult>;
  isOpen: boolean;
  shouldBypassSystemCheck: boolean;
  recheck: () => void;
  setBypass: (shouldBypass: boolean) => void;
  setModalState: (arg1: boolean) => void;
};
export const SystemCheckModal = ({
  isSystemValid,
  isOpen,
  shouldBypassSystemCheck,
  validationResults,
  setBypass,
  setModalState,
  recheck,
}: Props): React.ReactElement => {
  const isValidationInProgress = validationResults.length === 0;

  const { systemCheckText, systemCheckIcon, systemCheckIconColor, systemCheckBackground } = useMemo(
    () => getSysCheckStyleAttr(isSystemValid, shouldBypassSystemCheck, isValidationInProgress),
    [isSystemValid, shouldBypassSystemCheck, isValidationInProgress]
  );

  return (
    // @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element[]; open: boolean; maxWidth: string; css: string; "data-testid": string; }' is not assignable to type 'IntrinsicAttributes & DialogProps'.
    <Dialog open={isOpen} maxWidth="xl" css="min-height: 400px" data-testid="dialog-open">
      <DialogTitle>Sirona System Check</DialogTitle>
      <DialogContent
        css={`
          &.MuiDialogContent-root {
            padding-bottom: 0;
          }
        `}
      >
        <Container>
          <Grid
            columns={[`${ICON_COL_WIDTH} 1fr`]}
            rows={['1fr']}
            css={`
              gap: 1;
              align-content: space-evenly;
              align-items: center;
              margin: 0 0 ${spacings.xsmall}rem;
              padding: ${spacings.xsmall}rem ${H_PADDING};
              background-color: ${systemCheckBackground};
              border-radius: 4px;
              transition: background-color 0.2s ease-in-out;
            `}
          >
            <Stack>
              <Icon
                size="small"
                name={systemCheckIcon}
                css={`
                  color: ${systemCheckIconColor};
                  transition: color 0.2s ease-in-out;
                `}
              />
            </Stack>
            <Stack
              css={`
                font-weight: bold;
              `}
            >
              {systemCheckText}
            </Stack>
          </Grid>

          {validationResults.length === 0 && (
            <Stack alignX="center">
              <CircularProgress
                size="20px"
                css={`
                  margin: 3rem 0 2rem;
                  color: ${Colors.gray6};
                `}
                data-testid="syscheck-progress"
              />
            </Stack>
          )}
          {validationResults.map((validationResult) => (
            <div key={validationResult.id}>
              <Grid
                columns={[`${ICON_COL_WIDTH} 1fr`]}
                rows={['1fr']}
                css={`
                  gap: 1;
                  padding: 0 ${H_PADDING};
                `}
              >
                <Stack css="align-self: center;">
                  <Icon
                    size="small"
                    name={validationResult.result === true ? 'check' : 'clear'}
                    css={`
                      color: ${validationResult.result === true ? Colors.green4 : Colors.red4};
                    `}
                  />
                </Stack>
                <Stack>{validationResult.reason}</Stack>
              </Grid>

              {validationResult.result === false && (
                <div
                  css={`
                    padding-left: calc(${ICON_COL_WIDTH} + ${H_PADDING});
                    font-size: ${sizes.textCaption};
                    margin-bottom: ${spacings.xsmall}rem;
                  `}
                >
                  <RemediationStep validatorId={validationResult.id} />
                </div>
              )}
            </div>
          ))}

          <Stack vertical alignX="center" css="margin: 3rem 0 0rem;">
            {isSystemValid === false && !isValidationInProgress && <RefreshNotice />}
            <Button
              disabled={isValidationInProgress}
              variant="ghost"
              icon={<Refresh />}
              onClick={recheck}
              data-testid="btn-syscheck-recheck"
            >
              Recheck
            </Button>
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions css="flex-direction: column;">
        <Stack css="gap: 1rem;">
          <Tooltip
            content={!shouldBypassSystemCheck ? BYPASS_TOOLTIP_CONTENT : null}
            // @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element; content: string; placement: string; backgroundColor: "#a5adb1"; color: "#041420"; }' is not assignable to type 'IntrinsicAttributes & Props'.
            placement="bottom"
            backgroundColor={Colors.gray8}
            color={Colors.gray1}
          >
            <Button
              variant="light"
              onClick={() => setBypass(!shouldBypassSystemCheck)}
              data-testid="btn-syscheck-bypass"
            >
              {shouldBypassSystemCheck ? 'Reset Bypass' : 'Bypass'}
            </Button>
          </Tooltip>
          <Button
            disabled={!(shouldBypassSystemCheck || isSystemValid)}
            onClick={() => setModalState(false)}
            data-testid="btn-syscheck-continue"
          >
            Continue
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

/** Renders the remediation steps for each validator */
const RemediationStep = ({
  validatorId,
}: {
  validatorId: SysChkValidatorTypes;
}): React.ReactElement | null => {
  switch (validatorId) {
    case SysChkValidator.ExtensionInstallState:
      return (
        <div>
          <Stack
            justifyContent="center"
            css={`
              margin: ${spacings.xsmall}rem 0;
              color: ${Colors.red5};
              font-weight: bold;
              border-radius: 4px;
              gap: ${spacings.xxsmall}rem;
            `}
          >
            <Icon name="priority_high" size="small" />{' '}
            <span>You will be unable to read cases without this step.</span>
          </Stack>
          Install the <strong>Sirona Medical Workspace Manager</strong> extension from the{' '}
          <a href={CHROME_EXTENSION_INSTALL_PAGE} target="_blank" rel="noreferrer">
            Chrome Web Store
          </a>
          .
        </div>
      );
    case SysChkValidator.ExtensionMinVersion:
      return (
        <div>
          Update the Sirona Medical Workspace Manager extension by opening a new tab, navigating to{' '}
          <code>chrome://extensions</code> and selecting "Update" on the top left.
        </div>
      );
    default:
      return null;
  }
};
