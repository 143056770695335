import { NotEditable } from '../../../components/NotEditable';
import styled from 'styled-components';
import { Colors } from 'styles';
import { useSelected } from '../../../core/index';
import { forwardRef } from 'react';
import type { SectionHeaderPluginElement } from '../types';

type SectionHeaderProps = {
  element: SectionHeaderPluginElement;
  children: React.ReactNode;
};

const RenderableComponent = styled(NotEditable)`
  padding: 2px 4px;
  vertical-align: baseline;
  border-radius: 6px;
  background: ${Colors.teal2};
  z-index: ${(props: { selected: boolean }) => (props.selected ? 10 : 0)};
  color: ${(props: { selected: boolean }) => (props.selected ? Colors.blue6 : Colors.white)};
  cursor: pointer;
  box-shadow: ${(props: { selected: boolean }) =>
    props.selected ? `0 0 0 1px ${Colors.blue6}` : 'none'};
  &:after {
    content: ':';
  }
`;

const SectionHeaderComponent = forwardRef<SectionHeaderProps, SectionHeaderProps & Element>(
  (props, ref) => {
    const selected = useSelected();
    return (
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      <RenderableComponent ref={ref} element={props.element} selected={selected}>
        {props.children}
      </RenderableComponent>
    );
  }
);
SectionHeaderComponent.displayName = 'SectionHeaderComponent';

export const SectionHeader = SectionHeaderComponent;
export const SectionHeaderRenderer = SectionHeaderComponent;
