/* eslint-disable no-redeclare */

import { useEffect } from 'react';
// we use BroadcastChannel to send events to components subscribed on other pages
import { BroadcastChannel } from 'broadcast-channel';
// We use mitt to send events to components subscribed on the same page
import mitt from 'mitt';
import type { PageTypes } from 'utils/pageTypes';
import type { RecorderVoiceCommandProps } from 'domains/reporter/RichTextEditor';
import type { DeepLink } from 'domains/reporter/RichTextEditor/plugins/deepLink/types';
import type { ToastProps } from 'common/ui/Toaster/Toast';
import type { SnackBarIcon } from 'common/GenericSnackBar';
import type { Picklist } from 'domains/reporter/Template/usePicklist';
import type { TriggerToolInteractionMutation } from 'generated/graphql';

export const NAMESPACES = {
  DICTATION: 'dictation',
  FOCUS_WINDOW: 'focus_window',
  REPORTER_SHORTCUT: 'reporter_key_shortcut',
  URT_SHORTCUT: 'urt_key_shortcut',
  INSERT_SECTION_HEADER: 'insert_section_header',
  UPDATE_SECTION_HEADERS: 'update_section_headers',
  RECORDER_VOICE_COMMAND: 'recorder_voice_command',
  CROSS_TAB_NOTIFICATION: 'cross_tab_notification',
  CROSS_WINDOW_DATA_REFETCH: 'cross_window_data_refetch',
  ACTIVE_PICKLIST_DATA: 'active_picklist_data',
  DRE: 'dre',
  DEEP_LINK: 'deep_link',
  CROSS_WINDOW_MIRROR_LAYOUT: 'cross_window_mirror_layout',
} as const;

export type NameSpaces = (typeof NAMESPACES)[keyof typeof NAMESPACES];

type DictationMessage =
  | {
      type: 'hypothesisText';
      text: string;
    }
  | {
      type: 'stableText';
      text: string;
    };

type FocusWindow = {
  pageType: PageTypes;
  viewerId?: string;
};

type ReporterShortcut = {
  type: 'keyPress';
  action:
    | 'toggleRecording'
    | 'nextBracket'
    | 'previousBracket'
    | 'submitReport'
    | 'generateImpression'
    | 'medCheck'
    | 'discardReport'
    | 'draftReport';
};

type UrtShortcut = {
  type: 'keyPress';
  action: 'medCheck';
};

type InsertSectionHeader = {
  type: 'sectionHeader';
  conceptID: string;
  viewerRefID: string;
};

type UpdateSectionHeaders = {
  type: 'updateSectionHeaders';
  listOfNodes: Array<{
    conceptID: string;
    viewerRefID: string;
  }>;
};

type Dre =
  | {
      type: 'rotate';
      amount: number;
    }
  | {
      type: 'flip';
      axis: 'horizontal' | 'vertical';
    }
  | {
      type: 'next_series_set';
    }
  | {
      type: 'prev_series_set';
    }
  | {
      type: 'reset';
    }
  | {
      type: 'expand';
    }
  | {
      type: 'switch_viewtype';
    }
  | {
      type: 'SDKKeyboardShortcut';
      toolId: string;
      interactionId: string;
    }
  | {
      type: 'SDKTriggerToolInteractionResponse';
      responses: TriggerToolInteractionMutation['triggerToolInteraction'];
    };

export type ShowCrossTabNotification = {
  type: 'showCrossTabNotification';
  payload: {
    targetPageTypes: Array<PageTypes>;
  } & ToastProps & {
      message: string | React.ReactNode;
      // we can't send functions across tabs so we forbid them from being set to avoid confusion
      actionHandler?: undefined;
      onClose?: undefined;
      action?: undefined;
      icon?: SnackBarIcon;
    };
};

export type HideCrossTabNotification = {
  type: 'closeCrossTabNotification';
  payload: {
    id: string;
  };
};

export type CrossWindowDataRefetch = {
  type: 'claimedItems' | 'worklistItemGroup' | 'user';
};

export type ActivePicklistData = {
  type: 'activePicklistData';
  data: {
    activePicklist: Picklist | null | undefined;
    activePicklistOptionID: string;
  };
};

export type CrossTabNotification = ShowCrossTabNotification | HideCrossTabNotification;

type DeepLinkPayloads =
  | {
      type: 'INSERT';
      payload: DeepLink;
    }
  | {
      type: 'HANDLE_IMAGE';
      payload: DeepLink;
    }
  | {
      type: 'HANDLE_STUDY';
      payload: DeepLink;
    }
  | {
      type: 'HANDLE_STUDY';
      payload: DeepLink;
    };

export type Payload =
  | DictationMessage
  | FocusWindow
  | ReporterShortcut
  | DeepLinkPayloads
  | InsertSectionHeader
  | UpdateSectionHeaders
  | CrossTabNotification
  | CrossWindowDataRefetch
  | ActivePicklistData
  | Dre
  | RecorderVoiceCommandProps
  | boolean;

const bc = new BroadcastChannel<{
  namespace: NameSpaces;
  payload: Payload;
}>('srna-event-system');
const emitter = mitt();

/**
 * Use these functions anywhere to dispatch an event through the Events Manager
 */
export function sendEvent(namespace: typeof NAMESPACES.FOCUS_WINDOW, payload: FocusWindow): void;

export function sendEvent(namespace: typeof NAMESPACES.DICTATION, payload: DictationMessage): void;

export function sendEvent(
  namespace: typeof NAMESPACES.REPORTER_SHORTCUT,
  payload: ReporterShortcut
): void;

export function sendEvent(namespace: typeof NAMESPACES.URT_SHORTCUT, payload: UrtShortcut): void;

export function sendEvent(
  namespace: typeof NAMESPACES.INSERT_SECTION_HEADER,
  payload: InsertSectionHeader
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.DEEP_LINK,
  payload: {
    type: 'INSERT' | 'HANDLE_IMAGE' | 'HANDLE_STUDY';
    payload: DeepLink;
  }
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.UPDATE_SECTION_HEADERS,
  payload: UpdateSectionHeaders
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.CROSS_WINDOW_DATA_REFETCH,
  payload: CrossWindowDataRefetch
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.RECORDER_VOICE_COMMAND,
  payload: RecorderVoiceCommandProps
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.CROSS_TAB_NOTIFICATION,
  payload: CrossTabNotification
): void;

export function sendEvent(
  namespace: typeof NAMESPACES.ACTIVE_PICKLIST_DATA,
  payload: ActivePicklistData
): void;

export function sendEvent(namespace: typeof NAMESPACES.DRE, payload: Dre): void;

export function sendEvent(
  namespace: typeof NAMESPACES.CROSS_WINDOW_MIRROR_LAYOUT,
  payload: boolean
): void;

export function sendEvent(namespace: NameSpaces, payload: Payload): void {
  if (namespace == null) return;

  bc.postMessage({
    namespace,
    payload,
  });

  emitter.emit(namespace, payload);
}

export type Source = 'local' | 'remote';

// @ts-expect-error [EN-7967] - TS2394 - This overload signature is not compatible with its implementation signature.
export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.FOCUS_WINDOW,
  onEvent: ({ source, payload }: { source: Source; payload: FocusWindow }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.DICTATION,
  onEvent: ({ source, payload }: { source: Source; payload: DictationMessage }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.REPORTER_SHORTCUT,
  onEvent: ({ source, payload }: { source: Source; payload: ReporterShortcut }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.URT_SHORTCUT,
  onEvent: ({ source, payload }: { source: Source; payload: UrtShortcut }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.INSERT_SECTION_HEADER,
  onEvent: ({ source, payload }: { source: Source; payload: InsertSectionHeader }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.UPDATE_SECTION_HEADERS,
  onEvent: ({ source, payload }: { source: Source; payload: UpdateSectionHeaders }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.DEEP_LINK,
  onEvent: ({
    source,
    payload,
  }: {
    source: Source;
    payload: {
      type: 'INSERT' | 'HANDLE_IMAGE' | 'HANDLE_STUDY';
      payload: DeepLink;
    };
  }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.CROSS_WINDOW_DATA_REFETCH,
  onEvent: ({ source, payload }: { source: Source; payload: CrossWindowDataRefetch }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.RECORDER_VOICE_COMMAND,
  onEvent: ({ source, payload }: { source: Source; payload: RecorderVoiceCommandProps }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.CROSS_TAB_NOTIFICATION,
  onEvent: ({ source, payload }: { source: Source; payload: CrossTabNotification }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.ACTIVE_PICKLIST_DATA,
  onEvent: ({ source, payload }: { source: Source; payload: ActivePicklistData }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.DRE,
  onEvent: ({ source, payload }: { source: Source; payload: Dre }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: typeof NAMESPACES.CROSS_WINDOW_MIRROR_LAYOUT,
  onEvent: ({ source, payload }: { source: Source; payload: boolean }) => unknown
): void;

export function useEventsListener(
  subscribedNamespace: NameSpaces,
  onEvent: ({ source, payload }: { source: Source; payload: Payload }) => void
): void {
  useEffect(() => {
    const bcEventHandler = ({
      namespace,
      payload,
    }: {
      namespace: NameSpaces;
      payload: Payload;
    }) => {
      if (subscribedNamespace === namespace) {
        onEvent({ source: 'remote', payload });
      }
    };
    const localEventHandler = (payload?: Payload) => {
      if (payload == null) return;
      onEvent({ source: 'local', payload });
    };

    bc.addEventListener('message', bcEventHandler);
    emitter.on<Payload>(subscribedNamespace, localEventHandler);

    return () => {
      bc.removeEventListener('message', bcEventHandler);
      emitter.off<Payload>(subscribedNamespace, localEventHandler);
    };
  }, [subscribedNamespace, onEvent]);
}
