import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout, isRefreshTokenProvided } from 'utils/token';
import { useApolloClient, useMutation } from '@apollo/client';
import { AUTHENTICATE, AUTHENTICATE_MFA, UNAUTHENTICATE } from 'modules/Apollo/queries';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useGlobalAction } from 'hooks/useGlobalAction';
import { PATH } from 'config/constants';
import Cookie from 'js-cookie';
import { createContext } from '../common/ui/util/createContext';
import type { CreateContextReturn } from '../common/ui/util/createContext';
import { logger } from 'modules/logger';
import { clearStoredData } from './useClearStoredDataOnClose';
import { createPixelDataSharedWorker } from '../modules/viewer/workers/PixelDataSharedWorker';
import analytics from 'modules/analytics';
import { experimentConfigurationState } from 'domains/reporter/Reporter/ExperimentControlTab';
import type { ExperimentConfiguration } from 'domains/reporter/Reporter/ExperimentControlTab';
import { type User } from '../generated/graphql';
import { removeTypeName } from 'modules/analytics/util';
import { useSetUserRoles } from './useGetUserRoles';
import { workspace } from 'modules/analytics/constants';

export type Auth = {
  isAuthenticated: boolean;
  error: string | null | undefined; // unknown, LoginComponent is untyped but it only checks if error is not null,
  login: (arg1: { email: string; password: string }) => Promise<void>;
  loginMFA: (arg1: {
    email: string | null | undefined;
    totp: string | null | undefined;
    mfaSessionId: string | null | undefined;
  }) => Promise<void>;
  logout: () => void;
};

export const [AuthProvider, useAuthContext, AuthContext]: CreateContextReturn<Auth> =
  createContext<Auth>({
    strict: true,
    errorMessage:
      'useAuth: `context` is undefined. Seems you forgot to wrap component within the AuthProvider',
    name: 'AuthContext',
  });

const authState = atom({
  key: 'auth',
  default: isRefreshTokenProvided(),
});

export const useIsAuthenticated = (): boolean => useRecoilValue(authState);

function sleep(ms: number) {
  return new Promise((r: (result: Promise<undefined> | undefined) => void) => setTimeout(r, ms));
}

/**
 * Identifies a user for analytics after login
 */
function identifyUser(
  authenticateResponse?: User | null,
  experimentConfig?: ExperimentConfiguration
) {
  if (authenticateResponse == null) {
    logger.warn('Empty authentication response when attempting to identify user');
    return;
  }
  const { id, firstName, lastName, email, clinic, reporterSettings } = authenticateResponse;
  const additionalData = {
    segmentData: {
      experimentConfiguration: { ...experimentConfig },
      reporterSettings: removeTypeName(reporterSettings),
    },
  } as const;
  analytics.identify(
    {
      id,
      firstName,
      lastName,
      email,
      clinic: {
        name: clinic?.name || '',
        smid: clinic?.smid || '',
      },
    },
    additionalData
  );

  analytics.track(workspace.usr.login, {
    email,
    clinic: {
      name: clinic?.name ?? '',
      smid: clinic?.smid ?? '',
    },
  });
}

const useAuth = (): Auth => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authState);
  const [error, setError] = useState(null);
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticate] = useMutation(AUTHENTICATE);
  const [authenticateMFA] = useMutation(AUTHENTICATE_MFA);
  const [unauthenticate] = useMutation(UNAUTHENTICATE);
  const experimentConfiguration = useRecoilValue(experimentConfigurationState);

  const handleLogout = useCallback(async () => {
    try {
      await unauthenticate({
        // Suppress error when unauthenticating without a valid user,
        // such as when a user's refresh token expires.
        onError: () => {},
      });
    } finally {
      Cookie.set('refresh_token_provided', 'false');
    }
    analytics.reset();
    setIsAuthenticated(false);
    createPixelDataSharedWorker().port.postMessage({
      type: 'cancel-transfer',
    });
    // Delay 1 second to provide some buffer to cancel in-flight requests
    await sleep(1000);
    await clearStoredData();
    setError(null);

    logout();
    client.stop();
    await client.cache.reset();
    await client.resetStore();
  }, [client, setIsAuthenticated, unauthenticate]);

  const handleGlobalLogout = useGlobalAction('use-auth-logout', handleLogout, { callSelf: true });

  const handleAuthenticate = useCallback(() => {
    setIsAuthenticated(true);
  }, [setIsAuthenticated]);

  const setIsAuthenticatedGlobally = useGlobalAction('use-auth-login', handleAuthenticate, {
    callSelf: true,
  });

  const setUserRoles = useSetUserRoles();

  const handleLogin = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setError(null);
      try {
        const response = await authenticate({ variables: { email, password } });

        const authenticateResponse = response.data?.authenticate;

        if (!authenticateResponse) {
          throw new Error('Invalid authentication response');
        }

        // Extract roles from user object to avoid storing them in GraphQL cache
        const { roles, ...user } = authenticateResponse;

        setUserRoles(roles);

        const mfaSessionId = user?.mfaSessionId;

        if (mfaSessionId) {
          navigate(PATH.VERIFY_MFA, {
            state: {
              mfa_session: mfaSessionId,
              email,
            },
          });
        } else {
          logger.debug('Clearing localforage cache on login');
          // Clear localforage cache on login so that we don't keep stale data and we allow users to
          // login with a different account and start from scratch
          await clearStoredData();
          // Expiration is hardcoded because the token doesn't provide one
          const expires = new Date();
          expires.setDate(expires.getDate() + 30);
          Cookie.set('refresh_token_provided', 'true', { expires });

          setError(null);
          setIsAuthenticatedGlobally();
          identifyUser(user, experimentConfiguration);
          const { from } = location.state || { from: { pathname: PATH.WORKLIST } };
          navigate(from);
        }
      } catch (err: any) {
        analytics.error(err, { email });
        setError(err);
        return;
      }
    },
    [
      authenticate,
      setUserRoles,
      navigate,
      setIsAuthenticatedGlobally,
      experimentConfiguration,
      location.state,
    ]
  );

  const handleMFALogin = useCallback(
    async ({
      email,
      totp,
      mfaSessionId,
    }: {
      email: string | null | undefined;
      totp: string | null | undefined;
      mfaSessionId: string | null | undefined;
    }) => {
      setError(null);
      try {
        logger.debug('Clearing localforage cache on login');
        // Clear localforage cache on login so that we don't keep stale data and we allow users to
        // login with a different account and start from scratch
        await clearStoredData();
        const response = await authenticateMFA({
          variables: { email, totp, mfaSessionId },
        });

        if (response.data?.authenticateMFA.roles) {
          setUserRoles(response.data.authenticateMFA.roles);
        }

        // Expiration is hardcoded because the token doesn't provide one
        const expires = new Date();
        expires.setDate(expires.getDate() + 30);
        Cookie.set('refresh_token_provided', 'true', { expires });

        setError(null);
        setIsAuthenticatedGlobally();
        identifyUser(response.data?.authenticateMFA, experimentConfiguration);
        const { from } = location.state || { from: { pathname: PATH.WORKLIST } };
        navigate(from);
      } catch (err: any) {
        analytics.error(err, { email });
        setError(err);
        return;
      }
    },
    [
      authenticateMFA,
      setIsAuthenticatedGlobally,
      experimentConfiguration,
      location.state,
      navigate,
      setUserRoles,
    ]
  );

  return {
    isAuthenticated,
    logout: handleGlobalLogout,
    login: handleLogin,
    loginMFA: handleMFALogin,
    error,
  };
};

export default useAuth;
