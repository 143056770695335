import { useFields } from './Fields.Context';
import type { FieldsProviderProps } from './Fields.Context';
import { FieldsEditor } from './Fields.Editor';
import { useRecoilValue } from 'recoil';
import { reportStatusState, ADDENDUM_EDITOR_EDITABLE_STATUSES } from '../state';

export const FieldsAddendumEditor = ({
  isDisabled,
  onInit,
}: Readonly<{
  isDisabled: boolean;
  onInit?: FieldsProviderProps['onInit'];
}>): React.ReactElement | null => {
  const [{ addendum }, { onAddendumChange }] = useFields();
  const status = useRecoilValue(reportStatusState);
  if (addendum == null) return null;

  return (
    <FieldsEditor
      content={addendum.content}
      onChange={onAddendumChange}
      isDisabled={isDisabled}
      onInit={onInit}
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"addendumAdd"'.
      variant={ADDENDUM_EDITOR_EDITABLE_STATUSES.includes(status) ? 'report' : 'view'}
      isAddendumEditor={true}
      label={addendum.name}
    />
  );
};
