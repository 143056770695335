import styled from 'styled-components';

import {
  BoldRenderer,
  ItalicRenderer,
  UnderlineRenderer,
  ParagraphRenderer,
  InlineBookmarkRenderer,
  OrderedListRenderer,
  UnorderedListRenderer,
  PicklistRenderer,
  PlaceholderRenderer,
  HighlightedRenderer,
  HeadingRenderer,
  LineBreakRenderer,
  TextSourceStylingRenderer,
  HeadingErrorRenderer,
} from '../plugins';
import type { ElementPlugin } from '../plugins';
import { ElementComponent } from './Element';
import { unreachableCaseLog } from 'types';
import type { RendererRenderElement, RendererRenderText } from './types';
import { SectionHeaderRenderer } from '../plugins/sectionHeader/components/index';
import { DeepLinkRenderer, ReadonlyDeepLinkRenderer } from '../plugins/deepLink/components/index';
import type { Styles } from 'generated/graphql';
import { getDefaultReporterStyles } from 'hooks/useReporterStyles';
import { LIST_VARIANTS } from '../plugins/list/constants';
import { ReadonlyInlineBookmarkRenderer } from '../plugins/inlineBookmark/components/InlineBookmark';

export type RendererProps = Readonly<{
  content: ElementPlugin[];
  renderElement?: RendererRenderElement;
  renderText?: RendererRenderText;
  style?: Styles;
  className?: string;
  isReadOnly?: boolean;
}>;

const RendererContainer = styled.div``;

// @ts-expect-error [EN-7967] - TS2322 - Type '({ mark, children }: Readonly<{ children: ReactNode; mark: MarkPluginID; }>) => string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<...> | Element' is not assignable to type 'RendererRenderText'.
const defaultRenderText: RendererRenderText = ({ mark, children }) => {
  switch (mark) {
    case 'bold':
      return <BoldRenderer>{children}</BoldRenderer>;
    case 'italic':
      return <ItalicRenderer>{children}</ItalicRenderer>;
    case 'underline':
      return <UnderlineRenderer>{children}</UnderlineRenderer>;
    case 'highlighted':
      return <HighlightedRenderer>{children}</HighlightedRenderer>;
    case 'headingError':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <HeadingErrorRenderer>{children}</HeadingErrorRenderer>;
    case 'source':
      // $FlowFixMe[prop-missing]
      // @ts-expect-error [EN-7967] - TS2739 - Type '{ children: ReactNode; }' is missing the following properties from type 'RenderLeafProps<BaseText>': leaf, text, attributes
      return <TextSourceStylingRenderer>{children}</TextSourceStylingRenderer>;
    default:
      unreachableCaseLog(mark);
      return children;
  }
};

const defaultRenderElement: RendererRenderElement = ({ children, node }) => {
  switch (node.type) {
    case 'inlineBookmark':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <InlineBookmarkRenderer element={node}>{children}</InlineBookmarkRenderer>;
    case 'paragraph':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <ParagraphRenderer element={node}>{children}</ParagraphRenderer>;
    case 'list':
      if (node.variant === LIST_VARIANTS.ol) {
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        return <OrderedListRenderer element={node}>{children}</OrderedListRenderer>;
      } else if (node.variant === LIST_VARIANTS.ul) {
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        return <UnorderedListRenderer element={node}>{children}</UnorderedListRenderer>;
      } else return null;
    case 'picklist':
      return <PicklistRenderer element={node}>{children}</PicklistRenderer>;
    case 'placeholder':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <PlaceholderRenderer element={node}>{children}</PlaceholderRenderer>;
    case 'sectionHeader':
      // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactNode & HTMLCollection'.
      return <SectionHeaderRenderer element={node}>{children}</SectionHeaderRenderer>;
    case 'deepLink':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <DeepLinkRenderer element={node}>{children}</DeepLinkRenderer>;
    case 'heading':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <HeadingRenderer element={node}>{children}</HeadingRenderer>;
    case 'lineBreak':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <LineBreakRenderer element={node}>{children}</LineBreakRenderer>;
    default:
      // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'never'.
      unreachableCaseLog(node.type);
      return null;
  }
};

export const readOnlyRenderElement: RendererRenderElement = ({ children, node }) => {
  switch (node.type) {
    case 'inlineBookmark':
      return (
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        <ReadonlyInlineBookmarkRenderer element={node} leftDelimiter="" rightDelimiter="">
          {children}
        </ReadonlyInlineBookmarkRenderer>
      );
    case 'paragraph':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <ParagraphRenderer element={node}>{children}</ParagraphRenderer>;
    case 'list':
      if (node.variant === LIST_VARIANTS.ol) {
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        return <OrderedListRenderer element={node}>{children}</OrderedListRenderer>;
      } else if (node.variant === LIST_VARIANTS.ul) {
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        return <UnorderedListRenderer element={node}>{children}</UnorderedListRenderer>;
      } else return null;
    case 'picklist':
      return (
        <PicklistRenderer leftDelimiter="" rightDelimiter="" element={node}>
          {children}
        </PicklistRenderer>
      );
    case 'placeholder':
      return (
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        <PlaceholderRenderer element={node} leftDelimiter="" rightDelimiter="">
          {children}
        </PlaceholderRenderer>
      );
    case 'sectionHeader':
      // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactNode & HTMLCollection'.
      return <SectionHeaderRenderer element={node}>{children}</SectionHeaderRenderer>;
    case 'deepLink':
      // @ts-expect-error [EN-7967] - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. | TS2322 - Type 'DeepLinkPluginElement' is not assignable to type 'never'.
      return <ReadonlyDeepLinkRenderer element={node}>{children}</ReadonlyDeepLinkRenderer>;
    case 'heading':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <HeadingRenderer element={node}>{children}</HeadingRenderer>;
    case 'lineBreak':
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      return <LineBreakRenderer element={node}>{children}</LineBreakRenderer>;
    default:
      // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'never'.
      unreachableCaseLog(node.type);
      return null;
  }
};

export const Renderer = ({
  content,
  renderElement,
  renderText = defaultRenderText,
  // @ts-expect-error [EN-7967] - TS2339 - Property 'styles' does not exist on type 'Readonly<{ content: ElementPlugin[]; renderElement?: RendererRenderElement; renderText?: RendererRenderText; style?: Styles; className?: string; isReadOnly?: boolean; }>'.
  styles = getDefaultReporterStyles(),
  isReadOnly = false,
  ...rest
}: RendererProps): React.ReactElement => {
  return (
    <RendererContainer {...rest} style={styles.bodyStyle}>
      {content.map((node, index) => (
        <ElementComponent
          key={index}
          node={node}
          renderElement={
            renderElement ?? (isReadOnly ? readOnlyRenderElement : defaultRenderElement)
          }
          renderText={renderText}
        />
      ))}
    </RendererContainer>
  );
};
