import { Flow } from 'flow-to-typescript-codemod';

import type { InlineBookmarkPluginElement } from '../types';
import { ReadonlyInlineTemplateElement } from '../../common/inlineTemplate';
import { EMPTY } from 'config/constants';
import { forwardRef } from 'react';

type ReadonlyInlineBookmarkElementProps = {
  selected?: boolean;
  element: InlineBookmarkPluginElement;
  children: React.ReactNode;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
};

// @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardRefExoticComponent<HTMLElement & RefAttributes<ReadonlyInlineBookmarkElementProps>>' is not assignable to type 'AbstractComponent<ReadonlyInlineBookmarkElementProps, HTMLElement>'.
export const ReadonlyInlineBookmarkElement: Flow.AbstractComponent<
  ReadonlyInlineBookmarkElementProps,
  HTMLElement
> = forwardRef<ReadonlyInlineBookmarkElementProps, HTMLElement>(
  // @ts-expect-error [EN-7967] - TS2339 - Property 'selected' does not exist on type 'HTMLElement'. | TS2339 - Property 'element' does not exist on type 'HTMLElement'.
  ({ selected, element, attributes = EMPTY.OBJECT, ...rest }, ref) => {
    return (
      // @ts-expect-error [EN-7967] - TS2322 - Type '{ selected: any; testID: string; "data-selected": any; element: any; accessKey: string; accessKeyLabel: string; autocapitalize: string; dir: string; draggable: boolean; hidden: boolean; inert: boolean; ... 308 more ...; ref: ForwardedRef<...>; } | { ...; }' is not assignable to type 'IntrinsicAttributes & Readonly<{ children: ReactNode; selected?: boolean; testID?: string; "data-editor-element"?: string; "data-slate-node"?: "element"; "data-slate-inline"?: true; ... 5 more ...; rightDelimiter?: string; }> & RefAttributes<...>'.
      <ReadonlyInlineTemplateElement
        ref={ref}
        {...rest}
        {...attributes}
        selected={selected}
        testID="inlineBookmark"
        data-selected={selected}
        element={element}
      />
    );
  }
);
ReadonlyInlineBookmarkElement.displayName = 'ReadonlyInlineBookmarkElement';
