import { Editable, useEditor, Range } from '../core';
import type { EditableProps } from '../core';
import { compose } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useGamepadBindings } from 'utils/gamepad';

import { usePlugins, useNodeStateRef, useRangeMasksState } from '../hooks';
import type { NodeEntry } from 'slate';
import { useRecorder } from 'common/Recorder/useRecorder/useRecorder';
import { logger } from 'modules/logger';
import { useMicrophone } from '../../useMicrophone/useMicrophone';
import { stringifyRange } from '../utils/stringify';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { getHeadingIfCursorRightAtEnd, isLastCharacterAColon } from '../stitching/fragmentHelpers';
import { Node, Transforms } from 'slate';
import { getSurroundingTextString } from '../utils/getSurroundingTextString';
import { insertClipboardDataAsFragment } from '../../Reporter/Fields/utils';

export type BaseEditorProps = EditableProps;

export const BaseEditor = (props: BaseEditorProps): React.ReactElement => {
  const {
    decoratePlugins,
    onDOMBeforeInputPlugins,
    onDictaphoneButtonPresses,
    onKeyDownPlugins,
    renderElementPlugins,
    renderLeafPlugins,
    getEditableEnhancers,
  } = usePlugins();
  const editor = useEditor();
  const nodeState = useNodeStateRef();
  const rangeMasks = useRangeMasksState();
  const [isReportContentUnmaskingEnabled] = useFeatureFlagEnabled(FF.REPORT_CONTENT_UNMASKED);
  const { isRecording } = useRecorder();
  const { audioInputDeviceLabel } = useMicrophone();
  const onDictaphoneButtonPress = useCallback(
    (actionID, pressed, keyCode) => {
      if (pressed) {
        onDictaphoneButtonPresses({ editor, nodeState })(actionID);

        const message = `[Editable] Dictaphone button press (key: ${keyCode}, action: ${actionID}) whilst ${isRecording ? '' : 'not '}recording. Cursor now at: "${getSurroundingTextString(
          editor
        )}"`;

        logger.info(message, {
          logId: editor?.selection ? stringifyRange(editor.selection) : '',
          audioInputDeviceLabel,
          selection: editor?.selection ?? '',
        });
      }
    },
    [isRecording, audioInputDeviceLabel, editor, onDictaphoneButtonPresses, nodeState]
  );
  useGamepadBindings({ callback: onDictaphoneButtonPress });

  const decorate = useCallback(
    (...args: NodeEntry[]) => {
      // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
      const pluginDecorations = decoratePlugins({ editor, nodeState })(...args);
      // If there is a range mask, filter out those decorations so they are hidden
      return pluginDecorations.filter((d) => {
        return !rangeMasks.some((rangeMask) => !!Range.intersection(d, rangeMask));
      });
    },
    [decoratePlugins, editor, nodeState, rangeMasks]
  );

  const onKeyDown = useCallback(
    // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    (...args: Array<never>) => onKeyDownPlugins({ editor, nodeState })(...args),
    [onKeyDownPlugins, editor, nodeState]
  );

  const onDOMBeforeInput = useCallback(
    // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    (...args: Event[]) => onDOMBeforeInputPlugins({ editor, nodeState })(...args),
    [onDOMBeforeInputPlugins, editor, nodeState]
  );

  const EnhancedEditable = useMemo(() => {
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'EnhanceEditable[]' is not assignable to parameter of type '[...func: ((a: any) => any)[], f7: (a: Readonly<{ editor: any; }>) => (props: unknown) => ReactElement<any, string | JSXElementConstructor<any>>, f6: (a: Readonly<...>) => Readonly<...>, f5: ... 4 more ..., f4: (props: Readonly<...>) => Readonly<...>]'.
    return compose(Editable, ...getEditableEnhancers());
  }, [getEditableEnhancers]);

  const onPaste = useCallback(
    (e) => {
      insertClipboardDataAsFragment(editor, e);
    },
    [editor]
  );

  const onMouseUp = useCallback(
    (e: never) => {
      if (editor.selection == null) {
        return;
      }
      /**
       * if user clicks so that it looks like this:
       *
       *  <heading level="1"><text>EXAMINATION:<cursor/></text></heading>
       *  ...
       *
       * move the cursor programmatically to the next inline paragraph
       * (https://sironamedical.atlassian.net/browse/RP-1854)
       */
      const headingEntry = getHeadingIfCursorRightAtEnd(editor, editor.selection);
      if (headingEntry) {
        const headingText = Node.string(headingEntry[0]);
        if (isLastCharacterAColon(headingText)) {
          Transforms.move(editor);
        }
      }
    },
    [editor]
  );

  return (
    <EnhancedEditable
      autoFocus
      decorate={decorate}
      decorations={rangeMasks}
      onDOMBeforeInput={onDOMBeforeInput}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      onMouseUp={onMouseUp}
      renderElement={renderElementPlugins}
      renderLeaf={renderLeafPlugins}
      data-testid="slate-content-editable"
      data-dd-privacy={isReportContentUnmaskingEnabled ? 'allow' : 'mask'}
      {...props}
      // We explicitly dont support drag and drop for now
      // @ts-expect-error [EN-7967] - TS2322 - Type '{ onDragStart: (e: any) => void; onDrop: (e: any) => void; style: { flex: string; maxWidth: string; }; decorate: (...args: NodeEntry[]) => Range[]; onDOMBeforeInput: (...args: Event[]) => void; ... 14 more ...; "data-dd-privacy": string; }' is not assignable to type 'IntrinsicAttributes & Readonly<{ editor: any; }>'.
      onDragStart={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
      }}
      style={{
        flex: '1',
        maxWidth: '100%',
      }}
    />
  );
};
