import { Flow } from 'flow-to-typescript-codemod';
import { useMemo } from 'react';
import { atomFamily, selectorFamily, atom, useRecoilValue, DefaultValue } from 'recoil';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import type {
  ViewportsConfigurations,
  ViewportConfiguration,
  StudyGroup,
  ViewportDisplayConfiguration,
  DehydratedViewportsConfigurations,
} from './types';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
  localForagePersisterEffect,
} from 'utils/recoilEffects';
import { generateViewportsConfigurations } from './factories';
import {
  layoutsForAllCasesState,
  DEFAULT_LAYOUTS,
} from 'domains/viewer/Viewer/StudyLoader/viewerLoaderState';
import { openedViewersState } from 'domains/viewer/TrackOpenedViewers';
import { useStudies } from 'domains/viewer/Viewer/StudyLoader/useStudies';
import { activeViewportState } from 'config/recoilState';
import { isPreviewingHangingProtocolState } from '../HangingProtocol/state';
import {
  dehydrateViewportsConfigurations,
  hydrateViewportConfiguration,
  hydrateViewportsConfigurations,
} from './manipulators';
import type { ViewportTypeKeys } from 'config/constants';
import { equals } from 'ramda';
import type { VtkAnnotation, Segment } from '../ViewportDre/Annotations/types';
import type { Layouts } from '../Viewer/StudyLoader/viewerLoaderState';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { env } from 'config/env';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';

type ViewportsConfigurationsState = {
  created: number | null | undefined;
  updated: number | null | undefined;
  data: DehydratedViewportsConfigurations | null | undefined;
};

type GeneratorArgs = {
  defaults?: Partial<ViewportConfiguration>;
  groupedStudies: StudyGroup[];
};

type ViewportsConfigurationsSelectorArgs = Readonly<{
  caseSmid: string | null | undefined;
  generatorArgs: GeneratorArgs;
  toJSON: () => Record<any, any> | string;
}>;

type ViewportConfigurationSelectorArgs = Readonly<{
  caseSmid: string | null | undefined;
  viewportId: string;
  generatorArgs: GeneratorArgs;
  toJSON: () => Record<any, any> | string;
}>;

/**
 * This state is responsible of storing any user-defined viewport configurations.
 * We don't need to store anything that is automatically generated since that's
 * predictable and can be regenerated from the data.
 * This atom also includes two timestamps (created and updated) to help us
 * delete old configurations that are no longer needed.
 *
 * TODO: implement logic to automatically purge old configurations.
 */
export const viewportsConfigurationsState: (
  caseSmid: string
) => RecoilState<ViewportsConfigurationsState> = atomFamily({
  key: 'viewportsConfigurations',
  default: {
    created: Date.now(),
    updated: null,
    data: {},
  },
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect({ version: 2 })],
});

export const generateViewportsConfigurationsSelectorArgs = ({
  caseSmid,
  generatorArgs,
}: Flow.Diff<
  ViewportsConfigurationsSelectorArgs,
  {
    toJSON: () => Record<any, any> | string;
  }
>): ViewportsConfigurationsSelectorArgs => ({
  caseSmid,
  generatorArgs,
  toJSON: () => {
    const { defaults, groupedStudies } = generatorArgs;
    return JSON.stringify({
      caseSmid,
      defaults,
      groupedStudies: groupedStudies.map((gs) =>
        gs.map(
          (s) =>
            `${s.smid}:${s.seriesList.map((sl) => sl.smid).join(',')}:${s.stackedFrames
              .map((si) => si.smid)
              .join(',')}`
        )
      ),
    });
  },
});

export const generateViewportConfigurationSelectorArgs = ({
  caseSmid,
  viewportId,
  generatorArgs,
}: Flow.Diff<
  ViewportConfigurationSelectorArgs,
  {
    toJSON: () => Record<any, any> | string;
  }
>): ViewportConfigurationSelectorArgs => ({
  caseSmid,
  viewportId,
  generatorArgs,
  toJSON: () => {
    const { defaults, groupedStudies } = generatorArgs;
    return JSON.stringify({
      caseSmid,
      defaults,
      viewportId,
      groupedStudies: groupedStudies.map((gs) =>
        gs.map(
          (s) =>
            `${s.smid}:${s.seriesList.map((sl) => sl.smid).join(',')}:${s.stackedFrames
              .map((si) => si.smid)
              .join(',')}`
        )
      ),
    });
  },
});

/*
 * Same as viewportsConfigurationsState but only returns the actual data
 * and takes care of updating the timestamps when mutations happen.
 * It also allows to pass a nullish case smid since our `useCaseSmid` hook
 * can return such datum.
 */
export const viewportsConfigurationsSelector: (
  args: ViewportsConfigurationsSelectorArgs
) => RecoilState<ViewportsConfigurations | null | undefined> = selectorFamily({
  key: 'viewportsConfigurationsSelector',
  get:
    ({ caseSmid, generatorArgs }) =>
    ({ get }) => {
      if (caseSmid == null) {
        return null;
      }

      const { defaults, groupedStudies } = generatorArgs;

      // the layoutsForAllCasesState only stores layouts set by the user
      // if there are no layouts set by the user, we use the ones defined
      // in the hanging protocol, if there are no layouts defined in the
      // hanging protocol, we use the default layouts
      const caseLayouts: Layouts | null | undefined = get(layoutsForAllCasesState)[caseSmid];
      const hangingProtocolLayouts: Layouts | null | undefined = get(hangingProtocolLayoutsState);
      const layouts = caseLayouts ?? hangingProtocolLayouts ?? DEFAULT_LAYOUTS;

      const windows = get(openedViewersState);
      const dehydratedViewportsConfigurations = get(viewportsConfigurationsState(caseSmid))?.data;
      const dehydratedHangingProtocolViewportsConfiguration = get(dehydratedHangingProtocol);

      const isPreviewingHangingProtocol = get(isPreviewingHangingProtocolState);
      const viewportsConfigurations =
        dehydratedViewportsConfigurations != null
          ? hydrateViewportsConfigurations(dehydratedViewportsConfigurations, groupedStudies)
          : null;
      const hangingProtocolViewportsConfiguration =
        dehydratedHangingProtocolViewportsConfiguration != null
          ? hydrateViewportsConfigurations(
              dehydratedHangingProtocolViewportsConfiguration,
              groupedStudies
            )
          : null;

      return generateViewportsConfigurations({
        defaults,
        groupedStudies,
        layouts,
        windows,
        viewportsConfigurations,
        hangingProtocolViewportsConfiguration,
        isPreviewingHangingProtocol,
      });
    },
  set:
    ({ caseSmid }) =>
    ({ set }, newData: DefaultValue | null | undefined | ViewportsConfigurations) => {
      if (caseSmid == null) return;
      if (newData instanceof DefaultValue) {
        set(viewportsConfigurationsState(caseSmid), (state) => ({
          created: state.created,
          updated: Date.now(),
          data: {},
        }));
      } else {
        const dehydratedData = newData != null ? dehydrateViewportsConfigurations(newData) : null;
        set(viewportsConfigurationsState(caseSmid), (state) => ({
          created: state.created,
          updated: Date.now(),
          data: dehydratedData,
        }));
      }
    },
});

/**
 * This state holds the information needed to adjust the rendered image
 * according to the user-provided settings and the one that have been
 * automatically defined by our logic.
 * It's stored separately and keyed by the case smid and the view type
 * so that we can leave them around and retrieve them later if needed.
 */
type ViewportDisplayConfigurationState = {
  created: number | null | undefined;
  updated: number | null | undefined;
  data: ViewportDisplayConfiguration | null | undefined;
};

export const viewportDisplayConfigurationState: (arg1: {
  stackSmid: string;
  viewType: ViewportTypeKeys;
  viewportId: string;
}) => RecoilState<ViewportDisplayConfigurationState> = atomFamily({
  key: 'viewportDisplayConfiguration',
  default: {
    created: Date.now(),
    updated: null,
    data: null,
  },
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

const viewportDisplayConfigurationsIdsState = atom<string[]>({
  key: 'viewer.viewportDisplayConfigurationsIdsState',
  default: [],
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

export const viewportDisplayHungStacksIdsState: RecoilState<string[]> = atom<string[]>({
  key: 'viewer.dre.viewportDisplayHungStacksIdsState',
  default: [],
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const mostRecentMatchingViewportDisplayConfigurationSelector: (arg1: {
  stackSmid: string | null | undefined;
  viewType: ViewportTypeKeys | null | undefined;
}) => RecoilValueReadOnly<ViewportDisplayConfiguration | null | undefined> = selectorFamily({
  key: 'viewer.mostRecentMatchingViewportDisplayConfigurationSelector',
  get:
    ({ stackSmid, viewType }) =>
    ({ get }) => {
      if (stackSmid == null || viewType == null) return null;

      const viewportDisplayConfigurationsIds = get(viewportDisplayConfigurationsIdsState);

      const viewportDisplayConfigurations: ViewportDisplayConfigurationState[] =
        viewportDisplayConfigurationsIds
          .map((viewportId) =>
            get(viewportDisplayConfigurationState({ stackSmid, viewType, viewportId }))
          )
          .filter(Boolean);

      const mostRecentViewportDisplayConfiguration:
        | ViewportDisplayConfigurationState
        | null
        | undefined = viewportDisplayConfigurations.sort((a, b) => {
        return (b.updated ?? 0) - (a.updated ?? 0);
      })[0];

      return mostRecentViewportDisplayConfiguration?.data;
    },
});

export const viewportDisplayConfigurationSelector: (arg1: {
  stackSmid: string | null | undefined;
  viewType: ViewportTypeKeys | null | undefined;
  viewportId: string | null | undefined;
}) => RecoilState<ViewportDisplayConfiguration | null | undefined> = selectorFamily({
  key: 'viewportDisplayConfigurationSelector',
  get:
    ({ stackSmid, viewType, viewportId }) =>
    ({ get }) => {
      if (stackSmid == null || viewType == null || viewportId == null) return null;
      const exactViewportDisplayConfiguration = get(
        viewportDisplayConfigurationState({ stackSmid, viewType, viewportId })
      ).data;

      let viewportDisplayConfiguration;

      if (exactViewportDisplayConfiguration != null) {
        viewportDisplayConfiguration = exactViewportDisplayConfiguration;
      } else {
        viewportDisplayConfiguration = get(
          mostRecentMatchingViewportDisplayConfigurationSelector({ stackSmid, viewType })
        );
      }

      return viewportDisplayConfiguration;
    },
  set:
    ({ stackSmid, viewType, viewportId }) =>
    ({ set, get }, newData: DefaultValue | null | undefined | ViewportDisplayConfiguration) => {
      if (stackSmid == null || viewType == null || viewportId == null) return;

      set(viewportDisplayConfigurationsIdsState, (state) =>
        Array.from(new Set([...state, viewportId]))
      );

      if (newData instanceof DefaultValue) {
        set(viewportDisplayConfigurationState({ stackSmid, viewType, viewportId }), (state) => ({
          created: state.created,
          updated: Date.now(),
          data: null,
        }));
      } else {
        set(viewportDisplayConfigurationState({ stackSmid, viewType, viewportId }), (state) => {
          // if the new data is equal to the stored data, we don't need to update
          // the timestamp as the data itself did not change, only exception is
          // when the state is a DefaultValue, in which case we want to update
          // the atom value so that it loses its DefaultValue status
          if (!(state instanceof DefaultValue) && equals(state.data, newData)) {
            return state;
          }

          return {
            created: state.created,
            updated: Date.now(),
            data: newData,
          };
        });
      }
    },
});

/*
 * Here we store the resulting ViewportsConfigurations obtained by resolving
 * the currently selected Hanging Protocol.
 */
export const hangingProtocolViewportConfigurationSelector: (
  arg1: ViewportConfigurationSelectorArgs
) => RecoilValueReadOnly<ViewportConfiguration | null | undefined> = selectorFamily({
  key: 'hangingProtocolViewportConfiguration',
  get:
    ({ caseSmid, viewportId, generatorArgs }) =>
    ({ get }) => {
      const { groupedStudies } = generatorArgs;
      const viewportConfiguration = get(dehydratedHangingProtocol)?.[viewportId];

      if (viewportConfiguration == null) {
        return null;
      }

      analytics.addContext(`${globalContext.viewer.viewportConfigurations}.${viewportId}`, {
        seriesSmid: viewportConfiguration?.series?.smid,
        stackSmid: viewportConfiguration?.stack?.smid,
        viewType: viewportConfiguration?.viewType,
      });

      return hydrateViewportConfiguration(viewportConfiguration, groupedStudies);
    },
});

export const dehydratedHangingProtocol: RecoilState<
  DehydratedViewportsConfigurations | null | undefined
> = atom({
  key: 'dehydratedHangingProtocol',
  default: null,
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
      __LEGACY_MIGRATION_DO_NOT_USE__disabled: env.NODE_ENV === 'test',
    }),
  ],
});

/*
 * Here we store the layouts that are available for the currently selected Hanging Protocol.
 */
export const hangingProtocolLayoutsState: RecoilState<Layouts | null | undefined> = atom({
  key: 'viewer.hangingProtocolLayoutsState',
  default: null,
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
      __LEGACY_MIGRATION_DO_NOT_USE__disabled: env.NODE_ENV === 'test',
    }),
  ],
});

/**
 * Here we store the resulting ViewportsConfigurations obtained by resolving
 * the currently previewed Hanging Protocol.
 */
export const hangingProtcolPreviewState: RecoilState<ViewportsConfigurations | null | undefined> =
  atom({
    key: 'hangingProtcolPreviewState',
    default: null,
    effects: [
      broadcastChannelSynchronizerEffect(),
      localForagePersisterEffect({
        version: 1,
        __LEGACY_MIGRATION_DO_NOT_USE__disabled: env.NODE_ENV === 'test',
      }),
    ],
  });

/**
 * This selector allows each viewport to retrieve its own configuration and update it.
 * The selector takes care of merging the new configuration with the rest of the viewer configurations.
 */
export const viewportConfigurationSelector: (
  arg1: ViewportConfigurationSelectorArgs
) => RecoilState<ViewportConfiguration | null | undefined> = selectorFamily({
  key: 'viewportConfiguration',
  get:
    ({ caseSmid, viewportId, generatorArgs }) =>
    ({ get }) => {
      const viewportConfiguration = get(
        viewportsConfigurationsSelector(
          generateViewportsConfigurationsSelectorArgs({ caseSmid, generatorArgs })
        )
      )?.[viewportId];

      analytics.addContext(`${globalContext.viewer.viewportConfigurations}.${viewportId}`, {
        seriesSmid: viewportConfiguration?.series?.smid,
        stackSmid: viewportConfiguration?.stack?.smid,
        viewType: viewportConfiguration?.viewType,
      });

      return viewportConfiguration;
    },
  set:
    ({ caseSmid, viewportId, generatorArgs }) =>
    (
      { get, set },
      viewportConfiguration: DefaultValue | null | undefined | ViewportConfiguration
    ) => {
      if (caseSmid == null) {
        console.warn(
          'viewportConfigurationSelector: caseSmid is required while setting a viewport configuration!'
        );
        return;
      }

      const isPreviewing = get(isPreviewingHangingProtocolState);

      // Users are not allowed to make changes to the viewports while previewing a hanging protocol.
      // If they tried to do so, it would be impossible to reset the viewports configurations state
      // to the original state.
      if (isPreviewing) return;

      set(
        viewportsConfigurationsSelector(
          generateViewportsConfigurationsSelectorArgs({ caseSmid, generatorArgs })
        ),
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type '(state: ViewportsConfigurations) => { [x: string]: DefaultValue | ViewportConfiguration; }' is not assignable to parameter of type 'DefaultValue | ViewportsConfigurations | ((prevValue: ViewportsConfigurations) => DefaultValue | ViewportsConfigurations)'.
        (state) => ({
          ...state,
          [viewportId]: viewportConfiguration,
        })
      );
    },
});

const VIEWPORT_CONFIGURATION_SELECTOR_DEFAULTS: Partial<ViewportConfiguration> = {
  viewType: 'TWO_D_DRE',
};

/**
 * This hook is useful to retrieve the whole viewports configurations configuration.
 *
 * Usage:
 * ```
 * const viewportsConfigurations = useRecoilValue(useViewportsConfigurationsSelector());
 * ```
 */
export function useViewportsConfigurationsSelector(
  {
    defaults,
  }: {
    defaults?: Partial<ViewportConfiguration>;
  } = { defaults: VIEWPORT_CONFIGURATION_SELECTOR_DEFAULTS }
): RecoilState<ViewportsConfigurations | null | undefined> {
  const currentCaseId = useCurrentCaseId();
  const { groupedStudies } = useStudies();

  return useMemo(
    () =>
      viewportsConfigurationsSelector(
        generateViewportsConfigurationsSelectorArgs({
          caseSmid: currentCaseId,
          generatorArgs: { defaults, groupedStudies },
        })
      ),
    [currentCaseId, defaults, groupedStudies]
  );
}

type GetViewportConfigurationSelectorProps = {
  viewportId: string;
  defaults?: Partial<ViewportConfiguration>;
  groupedStudies: Array<StudyGroup>;
  currentCaseId: string | null | undefined;
};

export function getViewportConfigurationSelector({
  viewportId,
  defaults = VIEWPORT_CONFIGURATION_SELECTOR_DEFAULTS,
  groupedStudies,
  currentCaseId,
}: GetViewportConfigurationSelectorProps): RecoilState<ViewportConfiguration | null | undefined> {
  return viewportConfigurationSelector(
    generateViewportConfigurationSelectorArgs({
      caseSmid: currentCaseId,
      viewportId,
      generatorArgs: {
        defaults: { viewType: 'TWO_D_DRE', ...defaults },
        groupedStudies,
      },
    })
  );
}

export function getHPViewportConfigurationSelector({
  viewportId,
  groupedStudies,
  currentCaseId,
}: GetViewportConfigurationSelectorProps): RecoilValueReadOnly<
  ViewportConfiguration | null | undefined
> {
  return hangingProtocolViewportConfigurationSelector(
    generateViewportConfigurationSelectorArgs({
      caseSmid: currentCaseId,
      viewportId,
      generatorArgs: {
        groupedStudies,
      },
    })
  );
}

export function useHPViewportConfigurationSelector({
  viewportId,
}: {
  viewportId: string;
}): RecoilValueReadOnly<ViewportConfiguration | null | undefined> {
  const currentCaseId = useCurrentCaseId();
  const { groupedStudies } = useStudies();

  return useMemo(
    () => getHPViewportConfigurationSelector({ viewportId, groupedStudies, currentCaseId }),
    [currentCaseId, groupedStudies, viewportId]
  );
}

/**
 * This hook is useful to retrieve a specific viewport configuration ensuring
 * the correct default configurations are generated starting from the currently
 * loaded studies.
 *
 * Usage:
 * ```
 * const viewportConfiguration = useRecoilValue(useViewportConfigurationSelector({ viewportId: 'Viewport-0-0-0' }));
 * ```
 */
export function useViewportConfigurationSelector({
  viewportId,
  defaults = VIEWPORT_CONFIGURATION_SELECTOR_DEFAULTS,
}: {
  viewportId: string;
  defaults?: Partial<ViewportConfiguration>;
}): RecoilState<ViewportConfiguration | null | undefined> {
  const currentCaseId = useCurrentCaseId();
  const { groupedStudies } = useStudies();

  return useMemo(
    () => getViewportConfigurationSelector({ viewportId, defaults, groupedStudies, currentCaseId }),
    [currentCaseId, defaults, groupedStudies, viewportId]
  );
}

/**
 * Same as `useViewportConfigurationSelector` but automatically returns the
 * guide viewport configuration selector instead of asking for a viewport id.
 */
export function useGuideViewportConfigurationSelector(
  {
    defaults,
  }: {
    defaults?: Partial<ViewportConfiguration>;
  } = { defaults: {} }
): RecoilState<ViewportConfiguration | null | undefined> {
  const viewportId = useRecoilValue(activeViewportState);
  return useViewportConfigurationSelector({
    viewportId,
    defaults,
  });
}

/**
 * This hook is useful to retrieve a specific viewport display configuration.
 *
 * Usage:
 * ```
 * const viewportDisplayConfiguration = useRecoilValue(useViewportDisplayConfigurationSelector({ viewportId: 'Viewport-0-0-0' }));
 * ```
 */
export function useViewportDisplayConfigurationSelector({
  viewportId,
}: {
  viewportId: string;
}): RecoilState<ViewportDisplayConfiguration | null | undefined> {
  const viewportConfiguration = useRecoilValue(useViewportConfigurationSelector({ viewportId }));
  return useMemo(
    () =>
      viewportDisplayConfigurationSelector({
        stackSmid: viewportConfiguration?.stack?.smid,
        viewType: viewportConfiguration?.viewType,
        viewportId,
      }),
    [viewportConfiguration?.stack?.smid, viewportConfiguration?.viewType, viewportId]
  );
}

/**
 * Same as `useViewportDisplayConfigurationSelector` but automatically returns the
 * guide viewport display configuration selector instead of asking for a viewport id.
 */
export function useGuideViewportDisplayConfigurationSelector(): RecoilState<
  ViewportDisplayConfiguration | null | undefined
> {
  const viewportId = useRecoilValue(activeViewportState);
  const viewportConfiguration = useRecoilValue(useGuideViewportConfigurationSelector());
  return viewportDisplayConfigurationSelector({
    stackSmid: viewportConfiguration?.stack?.smid,
    viewType: viewportConfiguration?.viewType,
    viewportId,
  });
}

export const annotationsCacheState: RecoilState<Array<VtkAnnotation>> = atom({
  key: 'annotationsCacheState',
  default: [],
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const segmentsForAnnotationSelector: (
  annotationId?: string | null | undefined
) => RecoilValueReadOnly<ReadonlyArray<Segment>> = selectorFamily({
  key: 'segmentsForAnnotationByIdSelector',
  get:
    (annotationId?: string | null) =>
    ({ get }) => {
      const annotations = get(annotationsCacheState);
      return annotations.find((annotation) => annotation.id === annotationId)?.segments ?? [];
    },
});

export const presentationStateAnnotationExistsForListOfSeriesSmidsSelector: (params: {
  seriesSmids: ReadonlyArray<string>;
  sopInstanceUID?: string;
}) => RecoilValueReadOnly<boolean> = selectorFamily({
  key: 'presentationStateAnnotationExistsForListOfSeriesSmidsSelector',
  get:
    (params: { seriesSmids: ReadonlyArray<string>; sopInstanceUID?: string }) =>
    ({ get }) => {
      const annotations = get(annotationsCacheState);
      if (params.seriesSmids.length === 0) return false;
      const list = annotations
        .filter((annotation) =>
          params.sopInstanceUID != null
            ? annotation.metadata.sopInstanceUID === params.sopInstanceUID
            : true
        )
        .some(
          (annotation) =>
            params.seriesSmids.includes(annotation.seriesSmid) && annotation.source === 'GSPS'
        );
      return list;
    },
});

export const usePresentationStateAnnotationExistsForListOfSeriesSmids = (
  seriesSmids: ReadonlyArray<string>,
  sopInstanceUID?: string
): boolean => {
  const selector = presentationStateAnnotationExistsForListOfSeriesSmidsSelector({
    seriesSmids,
    sopInstanceUID,
  });
  const annotationsExist = useRecoilValue(selector);
  return annotationsExist;
};
