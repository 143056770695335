import type { InlineBookmarkPluginElement, InlineBookmarkPluginPropertyOptions } from '../types';

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { useReporterState } from '../../../hooks';
import { useSelected } from '../../../core';
import { INLINE_BOOKMARK_DELIMITER_LEFT, INLINE_BOOKMARK_DELIMITER_RIGHT } from '../constants';
import { InlineBookmarkElement } from './InlineBookmarkElement';
import { ReadonlyInlineBookmarkElement } from './ReadonlyInlineBookmarkElement';
import styled from 'styled-components';
import { unreachableCaseError } from 'types';
import { InlineBookmarkTemplate } from '.';

export const InlineBookmarkEditable: GetRenderElementComponentType<
  InlineBookmarkPluginPropertyOptions,
  InlineBookmarkPluginElement
> = ({ pluginOptions: { pluginID }, element, attributes, ...rest }): React.ReactElement | null => {
  const selected = useSelected();
  const { variant } = useReporterState();

  switch (variant) {
    case 'report':
      return (
        // @ts-expect-error [EN-7967] - TS2740 - Type '{ selected: boolean; element: Readonly<Readonly<{ children: any[]; }> & { name?: string; type: "inlineBookmark"; }>; variant: "report"; children: ReactNode; ... 5 more ...; ref: Ref<...>; }' is missing the following properties from type 'HTMLElement': accessKey, accessKeyLabel, autocapitalize, draggable, and 300 more.
        <InlineBookmarkElement
          {...attributes}
          {...rest}
          selected={selected}
          element={element}
          variant={variant}
        />
      );
    case 'fragment':
    case 'template':
      return (
        <InlineBookmarkTemplate
          {...rest}
          variant={variant}
          attributes={attributes}
          selected={selected}
          leftDelimiter={INLINE_BOOKMARK_DELIMITER_LEFT}
          rightDelimiter={INLINE_BOOKMARK_DELIMITER_RIGHT}
          element={element}
        />
      );
    case 'view':
      return null;

    default:
      unreachableCaseError(variant);
  }
};

export const InlineBookmarkRenderer = styled(InlineBookmarkElement)`
  white-space: pre-wrap;
`;

export const ReadonlyInlineBookmarkRenderer = styled(ReadonlyInlineBookmarkElement)`
  white-space: pre-wrap;
`;
