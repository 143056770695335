import { emitterEvents } from './BaseImagingProvider';
import type { BaseImagingProviderArgs } from './BaseImagingProvider';
import colors from 'styles/colors';
import { LRUMap } from 'lru_map';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import type { DreAllViewportTypes } from 'config/constants';
import debounce from 'lodash.debounce';
import analytics from 'modules/analytics';
import { viewer } from 'modules/analytics/constants';
import type {
  HandleFrameMessage,
  HandleInitialFrameMessage,
} from 'modules/viewer/workers/PixelWorkerConnection';
import type { FrameData } from 'modules/viewer/imageloaders/BaseWebSocketImageLoader';
import type { SupportedTextureTypes } from 'utils/textureUtils';
import { createVtkImageDataInstanceForFrame } from './utils';
import type { FullSingleLayerStack } from '../../../ViewportsConfigurations/types';
import { BaseSingleStackImagingProvider } from './BaseSingleStackImagingProvider';

/**
 * Provides a progressive style implementation for loading images for a series.
 *
 * Fetches batches of instances from series as a tarball of `.dcm` files
 * When a batch is fetched, we resolve it into a vtkImageData, extract the scalar array, and insert it into a pixel buffer representing the entire series
 * We can start shwowing viewports as soon as wel have scalars, so for large datasets, we can provide interactivity much more quickly
 */

type ProgressiveJobPayload = undefined;
// @ts-expect-error [EN-7967] - TS2344 - Type 'T' does not satisfy the constraint 'Readonly<FullBaseStack>'.
export type ProgressiveImagingProviderArgs<T> = BaseImagingProviderArgs<T> & {
  batchSize?: number;
};

export class ProgressiveImagingProvider<
  T extends FullSingleLayerStack = FullSingleLayerStack,
> extends BaseSingleStackImagingProvider {
  pixels: SupportedTextureTypes;
  minPixelValue: number;
  maxPixelValue: number;
  batchSize: number;
  _pixelMap: {
    [key: string]: SupportedTextureTypes;
  };
  _imageDataMap: {
    [key: string]: typeof vtkImageData;
  };
  _frameLoadingStateMap: {
    [key: string]: Set<string>;
  } = {}; // what is actively in memory
  _framesLoadedToCache: Set<string>; // what has been downloaded (use this to track loading progress)
  #sliceScrollingOrigin: number;
  #updateSliceScrollingOrigin: (arg1: number) => void;
  #vtkImageMap: Map<string, typeof vtkImageData>;

  constructor({
    batchSize = 20,
    // @ts-expect-error [EN-7967] - TS2339 - Property 'rerenderTriggerCount' does not exist on type 'ProgressiveImagingProviderArgs<T>'.
    rerenderTriggerCount = 100,
    ...baseImagingProviderArgs
  }: ProgressiveImagingProviderArgs<T>) {
    super(baseImagingProviderArgs);
    if (this._frameLoadingStateMap[baseImagingProviderArgs.stack.smid] == null) {
      this._frameLoadingStateMap[baseImagingProviderArgs.stack.smid] = new Set();
    }
    /**
     * The underlying typed array dataset for the series, rebuilt directly from the VTK images for each instance.
     */
    this.pixels = new Float32Array(0).fill(0);
    this._framesLoadedToCache = new Set();
    this._pixelMap = {};
    this._imageDataMap = {};
    /** Min + Max values for data in {this.pixels} */
    this.minPixelValue = 0;
    this.maxPixelValue = 0;
    /** Number of instances to fetch at once */
    this.batchSize = batchSize;
    // we are a progressive renderer
    this.type = 'progressive';
    this.unpackingMethod = 'tardah';
    this.#sliceScrollingOrigin = 0; // updated to initial slice on load
    this.#updateSliceScrollingOrigin = debounce(
      (newOrigin: number) => {
        this.#sliceScrollingOrigin = newOrigin;
      },
      50,
      {
        leading: true,
      }
    );
    this.#vtkImageMap = new Map();
  }

  isSopClass(sopClass: string): boolean {
    return this.stack.frames.every((frame) => frame.sopClassUID.startsWith(sopClass));
  }

  getVolume(): SupportedTextureTypes {
    return this.pixels;
  }

  getRange(): [number, number] | null {
    return this.minPixelValue !== 0 || this.maxPixelValue !== 0
      ? [this.minPixelValue, this.maxPixelValue]
      : null;
  }

  getFramesLoaded(): number {
    return this._frameLoadingStateMap[this.stack.smid].size;
  }

  isFrameLoaded(frameSmid: string): boolean {
    return this._frameLoadingStateMap[this.stack.smid].has(frameSmid);
  }

  isReadyToRender(): boolean {
    return (
      this._frameLoadingStateMap[this.stack.smid].size > 0 &&
      (this.status === 'loading' || this.status === 'complete')
    );
  }

  getImage(frameSmid?: string | null): typeof vtkImageData | null {
    if (this.is3Dable()) {
      return this.vtkImage;
    } else {
      if (frameSmid == null) return null;
      return this._imageDataMap[frameSmid] ?? null;
    }
  }

  getFramePixels(frameSmid: string): SupportedTextureTypes | null {
    return this._pixelMap[frameSmid] ?? null;
  }

  handleFrame(frameData: HandleFrameMessage | HandleInitialFrameMessage | FrameData): void {
    const frameIndex = this.stack.frames.findIndex((i) => i.smid === frameData.frameSmid);
    const shouldLoadFrameIntoMemory =
      this.loadType === 'full' ||
      (this.loadType === 'initial' && frameIndex === this.getActiveSlice('TWO_D_DRE'));

    if (shouldLoadFrameIntoMemory) {
      if (this.is3Dable()) {
        this.#handle3DableFrame(frameData, frameIndex);
      } else {
        this.#handleNon3DableFrame(frameData);
      }
      this._frameLoadingStateMap[this.stack.smid].add(frameData.frameSmid);
    }

    this.fromCache = this.fromCache || frameData.fromCache;
    this.minPixelValue = Math.min(frameData.range[0], this.minPixelValue);
    this.maxPixelValue = Math.max(frameData.range[1], this.maxPixelValue);

    // dirty, but very fast way to get cool loading bars
    const loadingBars = document.getElementsByClassName(`${frameData.frameSmid}-loader`);
    for (var i = loadingBars.length - 1; i >= 0; i--) {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'style' does not exist on type 'Element'.
      loadingBars[i].style.backgroundColor = colors.blue4;
    }
    // keep track of loaded instances in case we need to render a progress bar mid-load
    this._framesLoadedToCache.add(frameData.frameSmid);
    this.emitter.emit(emitterEvents.frameLoaded, this._framesLoadedToCache.size);

    if (this._framesLoadedToCache.size === this.stack.frames.length) {
      // final slice: update status, emit event, and render view one last time
      if (this.status !== 'error') this.setStatus('complete');
      this.emitter.emit(emitterEvents.stackLoaded);
    }
    this.viewRef.current?.requestRender();
  }

  #handle3DableFrame(
    message: HandleFrameMessage | HandleInitialFrameMessage | FrameData,
    frameIndex: number
  ) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'volume' does not exist on type 'FrameData | HandleFrameMessage'.
    const { pixels, volume } = message;
    // first frame
    if (this.pixels.length === 0) {
      // in unusual reloading scenarios, we might have to rebuild the volume on the fly
      // there is a unit test for this specific scenario
      // @ts-expect-error [EN-7967] - TS2351 - This expression is not constructable.
      this.pixels = volume ?? new pixels.constructor(pixels.length * this.stack.frames.length);
      this.emitter.emit(emitterEvents.volumeUpdated);

      const image = this.vtkImage;
      // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
      const scalars = image?.getPointData().getScalars();
      if (scalars) {
        scalars.setData(this.pixels);
      } else {
        const dataArray = vtkDataArray.newInstance({
          name: 'Scalars',
          numberOfComponents: this.getNumberOfColorChannels(frameIndex),
          values: this.pixels,
        });
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
        image.getPointData().setScalars(dataArray);
      }

      // the vtkImage may not have been modified, but we have new image data
      // and need to let stuff rerender regardless
      this.triggerImageChangeCallbacks(frameIndex);
      // @ts-expect-error [EN-7967] - TS2339 - Property 'BYTES_PER_ELEMENT' does not exist on type 'Function'. | TS2339 - Property 'BYTES_PER_ELEMENT' does not exist on type 'Function'.
    } else if (pixels.constructor.BYTES_PER_ELEMENT > this.pixels.constructor.BYTES_PER_ELEMENT) {
      // new images may arrive that are a different type
      // than what the volume initialized with, which is only based on the first frame's type
      // if so, we must build a larger volume and copy all the old data to the new, larger-typed volume
      // then emit an event to rerender existing viewports
      // @ts-expect-error [EN-7967] - TS2351 - This expression is not constructable.
      const newVolume = new pixels.constructor(pixels.length * this.stack.frames.length);
      newVolume.set(this.pixels);
      this.pixels = newVolume;
      this.emitter.emit(emitterEvents.volumeUpdated);

      this.triggerImageChangeCallbacks(frameIndex);
    }

    this.pixels.set(pixels, frameIndex * pixels.length);
  }

  #handleNon3DableFrame({
    pixels,
    frameSmid,
  }: HandleFrameMessage | HandleInitialFrameMessage | FrameData) {
    const firstTagIndex = this.frameTags.findIndex((t) => t.smid === frameSmid);
    const firstTag = this.frameTags[firstTagIndex];
    if (!firstTag) return;
    const image = this.#vtkImageMap.get(frameSmid) ?? createVtkImageDataInstanceForFrame(firstTag);
    if (!this.#vtkImageMap.has(frameSmid)) {
      this.#vtkImageMap.set(frameSmid, image);
    }

    const dataArray = vtkDataArray.newInstance({
      name: 'Scalars',
      numberOfComponents: 1, // labelmap with single component
      values: pixels,
    });

    // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
    this.vtkImage?.getPointData().setScalars(dataArray);
    // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
    image.getPointData().setScalars(dataArray);
    this._pixelMap[frameSmid] = pixels;
    this._imageDataMap[frameSmid] = image;
    this.triggerImageChangeCallbacks(firstTagIndex);
  }

  /******************************************************************
   * Overrides
   * handle scrolling when data is not always available
   ******************************************************************/

  async load(
    {
      initialSlice = 0,
      priority,
    }: {
      initialSlice?: number;
      priority: number;
    } = { priority: 0 }
  ): Promise<unknown> {
    this.#sliceScrollingOrigin = initialSlice;
    return super.load({ initialSlice, priority });
  }

  setActiveSlice(viewType: DreAllViewportTypes, viewportId: string, slice: number): void {
    // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'Set<string>' and 'number' have no overlap.
    if (this._frameLoadingStateMap.size === this.stackSize) {
      return super.setActiveSlice(viewType, viewportId, slice);
    }

    const previousSlice = this.getActiveSlice(viewType, viewportId);
    this.#updateSliceScrollingOrigin(previousSlice);

    const frameSmid = this.frameSmidsMap[slice];
    if (this._frameLoadingStateMap[this.stack.smid].has(frameSmid)) {
      return super.setActiveSlice(viewType, viewportId, slice);
    }

    if (this.is3Dable() && Math.abs(this.#sliceScrollingOrigin - slice) > 1) {
      // scrolling quickly, see if we can find a fallback slice to render instead
      for (let offsetIndex = 0; offsetIndex < OFFSETS.length; offsetIndex++) {
        const offsetFrameSmid = this.frameSmidsMap[slice + OFFSETS[offsetIndex]];
        if (this._frameLoadingStateMap[this.stack.smid].has(offsetFrameSmid)) {
          return super.setActiveSlice(viewType, viewportId, slice + OFFSETS[offsetIndex]);
        }
      }
    }

    analytics.track(viewer.usr.scrolledToLoadingSlice, {
      stackSmid: this.stack.smid,
      slice,
      previousSlice,
      percentLoaded:
        this._frameLoadingStateMap[this.stack.smid].size / Object.keys(this.frameSmidsMap).length,
      viewType,
      viewportId,
    });

    return super.setActiveSlice(viewType, viewportId, slice);
  }

  unloadAllFrames(): void {
    this.stack.frames.forEach((frame, frameIndex) => {
      this.unloadFrameFromMemory(frameIndex);
    });
  }

  unloadFrameFromMemory(frameIndex: number) {
    const frame = this.stack.frames[frameIndex];
    if (
      this._frameLoadingStateMap[this.stack.smid] != null &&
      this._frameLoadingStateMap[this.stack.smid].has(frame.smid)
    ) {
      if (this.is3Dable()) {
        // purge the entire volume
        this.pixels = new Uint8Array(0);
        this.emitter.emit(emitterEvents.volumeUpdated);
        this._frameLoadingStateMap[this.stack.smid] = new Set();

        // remove references to old pixel data
        const image = this.vtkImage;
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
        const scalars = image?.getPointData().getScalars();
        if (scalars) {
          scalars.setData(this.pixels);
        } else {
          const dataArray = vtkDataArray.newInstance({
            name: 'Scalars',
            numberOfComponents: this.getNumberOfColorChannels(frameIndex),
            values: this.pixels,
          });
          // @ts-expect-error [EN-7967] - TS2339 - Property 'getPointData' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
          image.getPointData().setScalars(dataArray);
        }
      } else {
        this._frameLoadingStateMap[this.stack.smid].delete(frame.smid);
        delete this._pixelMap[frame.smid];
        delete this._imageDataMap[frame.smid];
      }
    }
  }
}

const OFFSETS = [1, -1, 2, -2];

export const sliceEmissionJobs = new LRUMap<string, Promise<ProgressiveJobPayload>>(20);
