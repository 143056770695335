import { Flow } from 'flow-to-typescript-codemod';

import type { PicklistPluginElement } from '../types';
import { InlineTemplateElement } from '../../common/inlineTemplate';
import { PICKLIST_DELIMITER_LEFT, PICKLIST_DELIMITER_RIGHT } from '../constants';
import { EMPTY } from 'config/constants';
import { forwardRef } from 'react';

type PicklistElementProps = Readonly<{
  selected?: boolean;
  element: PicklistPluginElement;
  children: React.ReactNode;
  handleClick?: () => void;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
  leftDelimiter?: string;
  rightDelimiter?: string;
  variant?: string;
}>;

// @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardRefExoticComponent<Readonly<{ selected?: boolean; element: Readonly<Readonly<{ children: any[]; }> & { picklistID: string; type: "picklist"; name?: string; }>; children: ReactNode; ... 8 more ...; variant?: string; }> & HTMLElement & RefAttributes<...>>' is not assignable to type 'AbstractComponent<Readonly<{ selected?: boolean; element: Readonly<Readonly<{ children: any[]; }> & { picklistID: string; type: "picklist"; name?: string; }>; children: ReactNode; handleClick?: () => void; ... 7 more ...; variant?: string; }>, HTMLElement>'.
export const PicklistElement: Flow.AbstractComponent<PicklistElementProps, HTMLElement> =
  forwardRef<PicklistElementProps, PicklistElementProps & HTMLElement>(
    (
      {
        selected,
        element,
        handleClick,
        attributes = EMPTY.OBJECT,
        leftDelimiter = PICKLIST_DELIMITER_LEFT,
        rightDelimiter = PICKLIST_DELIMITER_RIGHT,
        variant,
        ...rest
      },
      ref
    ) => {
      return (
        <InlineTemplateElement
          // @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardedRef<Readonly<{ selected?: boolean; element: Readonly<Readonly<{ children: any[]; }> & { picklistID: string; type: "picklist"; name?: string; }>; children: ReactNode; handleClick?: () => void; ... 7 more ...; variant?: string; }>>' is not assignable to type 'LegacyRef<HTMLElement>'.
          ref={ref}
          data-selected={selected}
          {...rest}
          {...attributes}
          selected={selected}
          handleClick={handleClick}
          element={element}
          testID="picklist"
          leftDelimiter={leftDelimiter}
          rightDelimiter={rightDelimiter}
          variant={variant}
        />
      );
    }
  );

PicklistElement.displayName = 'PicklistElement';
