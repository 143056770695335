import { PATH } from 'config/constants';
import { matchPath, useLocation } from 'react-router-dom';

export const useReporterSubPath = (): string | null | undefined => {
  const location = useLocation();

  const paths = [
    PATH.REPORTER_MACRO_NEW,
    PATH.REPORTER_MACRO_CLONE,
    PATH.REPORTER_MACRO_EDIT,
    PATH.REPORTER_TEMPLATE_NEW,
    PATH.REPORTER_TEMPLATE_CLONE,
    PATH.REPORTER_TEMPLATE_EDIT,
  ];

  return paths.find((path) => matchPath(path, location.pathname) != null);
};
