import { useContext } from 'react';
import { useRecoilCallback } from 'recoil';
import { Contexts } from 'react-vtk-js';
import { angleBetweenVectors } from '@kitware/vtk.js/Common/Core/Math';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { useViewportId, useViewType, slicerBoundState, sizesState, scaleState } from './state';
import { calculateCameraParametersFromImageData, radiansToDegrees } from '../utils/math';
import { rotateFromImageCenter } from '../utils/rotateFromImageCenter';
import { viewportDisplayConfigurationSelector } from 'domains/viewer/ViewportsConfigurations';
import { vec3 as vector3, mat4 } from 'gl-matrix';

import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import { currentStackAtomFamily } from '../../Viewer/viewerRecoilContext';
import { useActiveSliceImage } from './useImage';
import { useViewCamera } from './useViewCamera';
import { DRE_MPR_VIEWPORT_TYPES } from 'config/constants';
import type { DreAllViewportTypes } from 'config/constants';

import type { IView } from 'react-vtk-js';

type FlipOperation = // viewport should be flipped from its original orientation

    | true // viewport should NOT be flipped from its original orientation
    | false // toggle the current flip state
    | null
    | undefined; // toggle the current flip state

export const DEFAULT_ZOOM_VALUE = 1;

export const convertZoomAndParallelScale = (
  sizes:
    | {
        width: number;
        height: number;
      }
    | null
    | undefined,
  image: vtkImageData,
  viewType: DreAllViewportTypes,
  value: number,
  mode: 'zoomToParallel' | 'parallelToZoom'
): number => {
  const defaultZoomScale = sizes ? calculateZoomScale(sizes, image) : 1;
  const heightOfImage = image.getDimensions()[1] * image.getSpacing()[1];

  const isMip = viewType === 'THREE_D_MIP';
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'DreAllViewportTypes' is not assignable to parameter of type '"AXIAL_DRE" | "CORONAL_DRE" | "SAGITTAL_DRE"'.
  const isMPR = DRE_MPR_VIEWPORT_TYPES.includes(viewType);
  const bounds = image.getBounds();
  const axialLength = bounds[5] - bounds[4];

  const vtkWorldUnit = isMip || isMPR ? axialLength : heightOfImage;

  return mode === 'zoomToParallel'
    ? (vtkWorldUnit / defaultZoomScale / 2) * value
    : (value * 2 * defaultZoomScale) / vtkWorldUnit;
};

const calculateZoomScale = (
  sizes: {
    width: number;
    height: number;
  },
  image: vtkImageData
): number => {
  const row = image.getDimensions()[0];
  const column = image.getDimensions()[1];
  let imageRatio = 1;
  const viewportRatio =
    sizes.height < sizes.width || sizes.height === 0 || sizes.width === 0
      ? 1
      : sizes.width / sizes.height;
  if (row != null && column != null) {
    imageRatio = row < column || row === 0 || column === 0 ? 1 : column / row;
  }

  return viewportRatio * imageRatio;
};

export const useSetZoom = (): ((zoom: number) => Promise<void>) => {
  const viewportId = useViewportId();
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);
  const setZoomConfiguration = useSetZoomConfiguration();
  const image = useActiveSliceImage();
  const viewType = useViewType();

  const setZoom = useRecoilCallback(
    ({ snapshot, set }) =>
      async (zoom: number) => {
        const camera = view?.getCamera();

        if (view == null || camera == null || image == null || viewportId == null) {
          return;
        }

        const sizes = await snapshot.getPromise(sizesState(viewportId));
        setZoomConfiguration(zoom);

        const parallelScale = convertZoomAndParallelScale(
          sizes,
          image,
          viewType,
          zoom,
          'zoomToParallel'
        );
        camera.setParallelScale(parallelScale);
        view.requestRender();
      },
    [image, setZoomConfiguration, view, viewportId, viewType]
  );
  return setZoom;
};

export const useSetParallelScaleToZoom = (): (() => Promise<void>) => {
  const setZoom = useSetZoom();
  const viewportId = useViewportId();
  const view = useContext(Contexts.ViewContext);
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'IView'.
  const camera = useViewCamera(view);
  const image = useActiveSliceImage();
  const viewType = useViewType();

  const setParallelScaleToZoom = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        if (image == null) return;

        const sizes = await snapshot.getPromise(sizesState(viewportId));
        const parallelScale = camera.getParallelScale();
        const zoom = convertZoomAndParallelScale(
          sizes,
          image,
          viewType,
          parallelScale,
          'parallelToZoom'
        );
        set(scaleState(viewportId), parallelScale);
        setZoom(zoom);
      },
    [camera, image, setZoom, viewportId, viewType]
  );
  return setParallelScaleToZoom;
};

const performFlipOperation = (
  camera: vtkCamera,
  sliceBounds: number[],
  vertical: boolean
): {
  position: vec3;
  focalPoint: vec3;
  viewUp: vec3;
} => {
  const sliceCenter = [
    (sliceBounds[0] + sliceBounds[1]) / 2,
    (sliceBounds[2] + sliceBounds[3]) / 2,
    (sliceBounds[4] + sliceBounds[5]) / 2,
  ];

  const T = mat4.create();
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'ReadonlyVec3'.
  mat4.translate(T, T, sliceCenter);

  // @ts-expect-error [EN-7967] - TS2322 - Type 'vec3' is not assignable to type 'Vector3'.
  const viewUp: vec3 = vertical
    ? vector3.negate([0, 0, 0], camera.getViewUp())
    : camera.getViewUp();

  if (vertical) {
    const left = vector3.create();
    vector3.cross(left, viewUp, camera.getDirectionOfProjection());
    mat4.rotate(T, T, Math.PI, left);
  } else {
    mat4.rotate(T, T, Math.PI, viewUp);
  }

  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'ReadonlyVec3'.
  mat4.translate(T, T, vector3.negate([0, 0, 0], sliceCenter));

  const position = vector3.create();
  const focalPoint = vector3.create();
  vector3.transformMat4(position, camera.getPosition(), T);
  vector3.transformMat4(focalPoint, camera.getFocalPoint(), T);

  return {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type 'Vector3'.
    position: [...position],
    // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type 'Vector3'.
    focalPoint: [...focalPoint],
    viewUp: [...viewUp],
  };
};

export const useFlip = (): {
  flipVertical: (isVerticallyFlipped: FlipOperation) => Promise<void>;
  flipHorizontal: (isHorizontallyFlipped: FlipOperation) => Promise<void>;
} => {
  const viewportId = useViewportId();
  const view = useContext(Contexts.ViewContext);
  const viewType = useViewType();
  const setFlip = useSetFlip();
  const setViewingParameters = useSetViewingParameters();

  const flipHorizontal = useRecoilCallback(
    ({ set, snapshot }) =>
      async (isHorizontallyFlipped: FlipOperation = null) => {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getCamera' does not exist on type 'unknown'.
        const camera = view?.getCamera();
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getRenderer' does not exist on type 'unknown'.
        const renderer = view?.getRenderer()?.get();
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        const slicerBounds = await snapshot.getPromise(slicerBoundState(viewportId));

        if (camera == null || renderer == null || stack == null) return;

        const viewportDisplayConfig = await snapshot.getPromise(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId })
        );

        const xFlipped = viewportDisplayConfig?.isHorizontallyFlipped;

        // If the desired state is already flipped, do nothing
        if (xFlipped === isHorizontallyFlipped) return;

        if (slicerBounds != null) {
          const flipped = performFlipOperation(camera, slicerBounds, false);
          setViewingParameters(flipped);
          setFlip({
            isVerticallyFlipped: viewportDisplayConfig?.isVerticallyFlipped,
            isHorizontallyFlipped: !viewportDisplayConfig?.isHorizontallyFlipped,
          });
        }
      },
    [view, viewportId, setFlip, setViewingParameters, viewType]
  );

  const flipVertical = useRecoilCallback(
    ({ set, snapshot }) =>
      async (isVerticallyFlipped: FlipOperation = null) => {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getCamera' does not exist on type 'unknown'.
        const camera = view?.getCamera();
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getRenderer' does not exist on type 'unknown'.
        const renderer = view?.getRenderer()?.get();
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        const slicerBounds = await snapshot.getPromise(slicerBoundState(viewportId));

        if (camera == null || renderer == null || stack == null) return;

        const viewportDisplayConfig = await snapshot.getPromise(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId })
        );

        // If the desired state is already flipped, do nothing
        const yFlipped = viewportDisplayConfig?.isVerticallyFlipped;
        if (yFlipped === isVerticallyFlipped) return;

        if (slicerBounds != null) {
          const flipped = performFlipOperation(camera, slicerBounds, true);
          setViewingParameters(flipped);
          setFlip({
            isVerticallyFlipped: !viewportDisplayConfig?.isVerticallyFlipped,
            isHorizontallyFlipped: viewportDisplayConfig?.isHorizontallyFlipped,
          });
        }
      },
    [view, viewportId, setFlip, setViewingParameters, viewType]
  );
  return { flipHorizontal, flipVertical };
};

export const useSetRoll = (): ((roll: number, rotate?: boolean) => Promise<void>) => {
  const view = useContext(Contexts.ViewContext);
  const viewType = useViewType();
  const setRotate = useSetRotate();
  const image = useActiveSliceImage();

  /*
   * Set the roll value to the camera, it takes an absolute value between 0 and 360.
   * NOTE: This doesn't shift the current roll value, it sets a new one.
   */
  const setRoll = useRecoilCallback(
    ({ snapshot, set }) =>
      async (newRoll: number, rotate: boolean = true) => {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getCamera' does not exist on type 'unknown'.
        const camera = view?.getCamera();
        if (view == null || camera == null || image == null) return;

        const { viewUp, focalPoint } = calculateCameraParametersFromImageData(viewType, image);

        if (rotate && viewUp !== camera.getViewUp()) {
          const currentRoll = radiansToDegrees(angleBetweenVectors(viewUp, camera.getViewUp()));
          const difference: number = currentRoll - newRoll;
          rotateFromImageCenter(camera, difference, focalPoint);
          // @ts-expect-error [EN-7967] - TS2339 - Property 'requestRender' does not exist on type 'unknown'.
          view.requestRender();
        }

        setRotate(newRoll);
      },
    [view, image, viewType, setRotate]
  );
  return setRoll;
};

const useSetFlip = () => {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setFlipConfigurationDebounced = useRecoilCallback(
    ({ snapshot, set }) =>
      async (flip: {
        isHorizontallyFlipped: undefined | boolean;
        isVerticallyFlipped: undefined | boolean;
      }) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;

        return set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              ...flip,
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setFlipConfigurationDebounced;
};

const useSetRotate = () => {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setRotateConfigurationDebounced = useRecoilCallback(
    ({ snapshot, set }) =>
      async (rotate: number) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              params2d: {
                ...config?.params2d,
                rotate: rotate === -0 ? 0 : rotate,
              },
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setRotateConfigurationDebounced;
};

export function useSetViewingParameters(): (arg1: {
  position: vec3;
  focalPoint: vec3;
  viewUp: vec3;
}) => Promise<unknown> {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setPositionConfiguration = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ position, focalPoint, viewUp }) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              params2d: {
                ...config?.params2d,
                position,
                focalPoint,
                viewUp,
              },
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setPositionConfiguration;
}

const useSetZoomConfiguration = () => {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setZoomConfiguration = useRecoilCallback(
    ({ snapshot, set }) =>
      async (zoom: number) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              params2d: {
                ...config?.params2d,
                zoom: zoom,
              },
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setZoomConfiguration;
};

export const useResizeCamera: (viewportId: string) => () => Promise<void> = (viewportId) => {
  const view = useContext(Contexts.ViewContext);
  const viewType = useViewType();
  const setZoom = useSetZoom();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));

        if (view == null || viewType == null || stack == null) return;

        const viewportDisplayConfig = await snapshot.getPromise(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId })
        );
        setZoom(viewportDisplayConfig?.params2d?.zoom ?? DEFAULT_ZOOM_VALUE);
        // @ts-expect-error [EN-7967] - TS2339 - Property 'requestRender' does not exist on type 'unknown'.
        view.requestRender();
      },
    [viewportId, view, viewType, setZoom]
  );
};

export function useSetWindowLevel(): (arg1: { window: number; level: number }) => Promise<unknown> {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setWindowLevelConfiguration = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ window, level }) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              params2d: {
                ...config?.params2d,
                window,
                level,
              },
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setWindowLevelConfiguration;
}

/**
 * Hook for returning a Recoil callback for setting the layered image's window level settings in the
 * viewport display config.
 */
export function useSetLayeredWindowLevel(): (arg1: {
  window: number;
  level: number;
}) => Promise<unknown> {
  const viewportId = useViewportId();
  const viewType = useViewType();

  const setLayeredWindowLevelConfiguration = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ window, level }) => {
        const stack = await snapshot.getPromise(currentStackAtomFamily(viewportId));
        if (stack == null) return;
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              layeredParams2d: {
                ...config?.layeredParams2d,
                window,
                level,
              },
            };
          }
        );
      },
    [viewType, viewportId]
  );
  return setLayeredWindowLevelConfiguration;
}
