import { useCallback } from 'react';
import { useEventsListener, sendEvent, NAMESPACES } from 'modules/EventsManager';
import { getPageType, PAGE_TYPES } from 'utils/pageTypes';
import type { PageTypes } from 'utils/pageTypes';
import { useMatch } from 'react-router-dom';
import { PATH } from 'config/constants';
import { focusEvent, dispatchExtensionEvent } from 'domains/extension/extensionEventCreators';

export const useFocusManager = (): void => {
  const match = useMatch(PATH.VIEWER);
  const viewerId = match?.params.windowId;

  useEventsListener(NAMESPACES.FOCUS_WINDOW, ({ payload }) => {
    const pageType = getPageType(window.location.href);
    if (payload.pageType === pageType) {
      if (pageType === PAGE_TYPES.VIEWER && payload.viewerId !== viewerId) {
        // wrong Viewer
        return;
      }
      dispatchExtensionEvent(focusEvent());
    }
  });
};
export type SendFocusEvent = (arg1: { pageType: PageTypes; viewerId?: string }) => void;

export const useSendFocusEvent = (): SendFocusEvent => {
  return useCallback(
    ({ pageType, viewerId }) => sendEvent(NAMESPACES.FOCUS_WINDOW, { pageType, viewerId }),
    []
  );
};
