import Dialog from '../Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'common/ui/Button';
import Text from 'common/ui/Text';
import { Stack } from '../Layout';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Colors } from 'styles';

type DialogConfirmProps = {
  slug: string;
  children: React.ReactNode;
  variant?: 'dark' | 'light';
  dialogWidth?: string;
  contentWidth?: string;
  confirmButtonVariant?: 'primary' | 'secondary' | 'light';
  cancelButtonVariant?: 'primary' | 'secondary' | 'light';
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => undefined | Promise<undefined>;
  onCancel?: () => void;
  isConfirmLoading?: boolean;
};

const DialogConfirm = ({
  slug,
  children,
  variant = 'light',
  dialogWidth = '360px',
  contentWidth = '295px',
  confirmButtonVariant = 'secondary',
  cancelButtonVariant = 'light',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  isConfirmLoading = false,
  ...props
}: DialogConfirmProps): React.ReactElement => {
  return (
    // @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element[]; variant: "dark" | "light"; css: string; }' is not assignable to type 'IntrinsicAttributes & DialogProps'.
    <Dialog
      {...props}
      variant={variant}
      css={`
        .MuiDialog-paper {
          padding: 45px 30px 25px 30px;
          width: ${dialogWidth};
        }
      `}
    >
      <DialogContent
        css={`
          width: ${contentWidth};
          text-align: center;
        `}
        data-testid={`${slug}-modal-testid`}
      >
        {children}
      </DialogContent>
      <DialogActions
        css={`
          justify-content: center;
          padding: 0px;
        `}
      >
        {onCancel !== undefined ? (
          <Button id={`${slug}-cancel-button`} variant={cancelButtonVariant} onClick={onCancel}>
            <Text
              css={`
                white-space: nowrap;
              `}
              color={cancelButtonVariant === 'light' ? 'black' : 'white'}
            >
              {cancelText}
            </Text>
          </Button>
        ) : null}
        {onConfirm !== undefined ? (
          <Button
            type="submit"
            id={`${slug}-submit-button`}
            variant={confirmButtonVariant}
            onClick={onConfirm}
            disabled={isConfirmLoading}
          >
            {isConfirmLoading ? (
              <Stack space="small">
                <div
                  css={`
                    margin: 0 12px -5px -15px;
                    padding: 2px 0 0 0;
                  `}
                >
                  <CircularProgress size="20px" style={{ color: Colors.white }} />
                </div>
                <Text
                  css={`
                    white-space: nowrap;
                  `}
                  color={Colors.gray7}
                >
                  {confirmText}
                </Text>
              </Stack>
            ) : (
              <Text
                css={`
                  white-space: nowrap;
                `}
                color={
                  confirmButtonVariant === 'secondary'
                    ? 'secondary'
                    : confirmButtonVariant === 'light'
                      ? 'black'
                      : 'white'
                }
              >
                {confirmText}
              </Text>
            )}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
export default DialogConfirm;
