import { forwardRef } from 'react';
import { transparentize } from 'color2k';
import { Colors } from 'styles';

type FieldContentWrapperProps = {
  isDisabled?: boolean;
  isFocused?: boolean;
  children: React.ReactNode;
  css?: unknown;
};

// @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardRefExoticComponent<Element & RefAttributes<FieldContentWrapperProps>>' is not assignable to type 'ComponentType<FieldContentWrapperProps>'.
export const FieldContentWrapper: React.ComponentType<FieldContentWrapperProps> = forwardRef<
  FieldContentWrapperProps,
  Element
  // @ts-expect-error [EN-7967] - TS2339 - Property 'isDisabled' does not exist on type 'Element'. | TS2339 - Property 'isFocused' does not exist on type 'Element'.
>(({ isDisabled = false, isFocused = false, children, ...rest }, ref) => {
  return (
    <div
      {...rest}
      // @ts-expect-error [EN-7967] - TS2322 - Type 'ForwardedRef<FieldContentWrapperProps>' is not assignable to type 'LegacyRef<HTMLDivElement>'.
      ref={ref}
      css={`
        color: ${Colors.gray10};
        background-color: ${isDisabled ? Colors.gray5 : transparentize(Colors.white, 0.97)};
        border-radius: 6px;
        border: 1px solid ${isFocused ? Colors.mainBlue : transparentize(Colors.white, 0.92)};
        padding: 12px 15px;
        position: relative;
      `}
    >
      {/* @ts-expect-error [EN-7967] - TS2322 - Type 'HTMLCollection' is not assignable to type 'ReactNode'. */}
      {children}
    </div>
  );
});
FieldContentWrapper.displayName = 'FieldContentWrapper';
