import { Dialog as MuiDialog } from '@material-ui/core';
import { Colors } from 'styles';

type DialogProps = {
  variant?: 'dark' | 'light';
};

const Dialog = ({ variant = 'dark', ...props }: DialogProps): React.ReactElement => {
  return (
    // @ts-expect-error [EN-7967] - TS2741 - Property 'open' is missing in type '{ css: string; }' but required in type 'DialogProps'.
    <MuiDialog
      {...props}
      css={`
        .MuiDialog-paper {
          width: 450px;
          padding: 3rem;
          background-color: ${variant === 'light' ? Colors.gray10 : Colors.gray2};
          color: ${variant === 'light' ? Colors.gray1 : Colors.gray10};
        }
        .MuiDialogContent-root {
          padding: 0 0 3rem 0;
        }
      `}
    />
  );
};
export default Dialog;
