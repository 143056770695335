import { Point, Range, Path, Node } from 'domains/reporter/RichTextEditor/core';
import { equals } from 'ramda';
import { find } from '../../utils/find';
import { Editor, Text, Transforms } from '../../core';
import type { NodeEntry } from '../../core';
import { DEEP_LINK_PLUGIN_ID } from './types';
import type { DeepLinkPluginElement, DeepLink } from './types';
import { unreachableCaseError } from 'types';
import {
  DEFAULT_STUDY_DATE_VALUE,
  MISSING_STUDY_DATE,
  MISSING_STUDY_DESCRIPTION,
  DEEP_LINK_VARIANT_TYPES,
} from './constants';
import { INLINE_BOOKMARK_PLUGIN_ID } from '../inlineBookmark/types';
import { PAGE_TYPES } from 'utils/pageTypes';
import { normalizeText } from 'domains/reporter/RichTextEditor/plugins/heading/utils/normalization';
import { compareAsc, compareDesc } from 'date-fns';
import { toUTCLocaleString } from 'utils/date';
import type { HeadingKeywords } from 'generated/graphql';
import type { ReviewedStudyByDate } from 'domains/viewer/ViewportDre/modules/imaging/useTrackViewedSlices';
import { ReactEditor } from 'slate-react';
import type { Key } from 'react';
import { logger } from 'modules/logger';

const getTextNode = (node: DeepLink, associatedStudy?: ReviewedStudyByDate) => {
  switch (node.variant) {
    case DEEP_LINK_VARIANT_TYPES.IMAGE_SLICE:
      return {
        text:
          node.context.seriesDisplayName != null
            ? `(series ${node.context.seriesDisplayName}, image ${node.context.imageNumber})`
            : `(image ${node.context.imageNumber})`,
      };
    case DEEP_LINK_VARIANT_TYPES.POINT:
      return {
        text: node.context.label,
      };
    case DEEP_LINK_VARIANT_TYPES.STUDY: {
      if (associatedStudy == null) break;

      const { studyDate, description } = associatedStudy;
      const formattedDate = studyDate != null ? toUTCLocaleString(studyDate) : MISSING_STUDY_DATE;
      const label = `${description ?? MISSING_STUDY_DESCRIPTION} ${formattedDate ?? MISSING_STUDY_DATE}`;
      return {
        text: label,
      };
    }
    default:
      unreachableCaseError((node as DeepLinkPluginElement).variant);
  }
};

/**
 * Insert a node where ever the current selection is in the editor.
 * @param {Editor} editor The editor to insert into.
 */
export const insertDeepLink = ({
  editor,
  at,
  node,
}: {
  editor: Editor;
  node: DeepLink;
  at?: Path | Point | Range | undefined;
}) => {
  //If this is an image slice variant - use that context to generate the text?
  Transforms.insertNodes(
    editor,
    {
      type: DEEP_LINK_PLUGIN_ID,
      ...node,
      children: [getTextNode(node)],
    },
    {
      at,
    }
  );
};

export const generateComparisonDeepLinkNode = (study: ReviewedStudyByDate): DeepLink => {
  return {
    target: PAGE_TYPES.VIEWER,
    variant: DEEP_LINK_VARIANT_TYPES.STUDY,
    context: {
      studySmid: study.smid,
    },
  };
};

export const isStudyDeepLink = (node: Node | DeepLink): boolean =>
  node != null &&
  node.variant === DEEP_LINK_VARIANT_TYPES.STUDY &&
  node.type === DEEP_LINK_PLUGIN_ID &&
  node.context != null &&
  node.context.studySmid != null &&
  typeof node.context.studySmid === 'string';

export const isOlderDeepLink = (
  node: Node | DeepLink,
  reviewedStudies: Map<string, ReviewedStudyByDate>,
  studyDate?: Date | number | null
): boolean => {
  if (node == null || isStudyDeepLink(node) === false) return false;
  const found = reviewedStudies.get(node.context.studySmid);
  if (found == null) return false;
  return (
    compareDesc(
      new Date(found.studyDate ?? DEFAULT_STUDY_DATE_VALUE),
      new Date(studyDate ?? DEFAULT_STUDY_DATE_VALUE)
    ) > 0
  );
};

export const isNewerDeepLink = (
  node: Node | DeepLink,
  reviewedStudies: Map<string, ReviewedStudyByDate>,
  studyDate?: Date | number | null
): boolean => {
  if (node == null || isStudyDeepLink(node) === false) return false;
  const found = reviewedStudies.get(node.context.studySmid);
  if (found == null) return false;
  return (
    compareAsc(
      new Date(found.studyDate ?? DEFAULT_STUDY_DATE_VALUE),
      new Date(studyDate ?? DEFAULT_STUDY_DATE_VALUE)
    ) > 0
  );
};

export const createDeepLinkWrapper = (deepLink: DeepLink): DeepLinkPluginElement => {
  return {
    type: DEEP_LINK_PLUGIN_ID,
    ...deepLink,
    children: [],
  };
};

/**
 * Find a node in the editor by its guid
 * @param {Editor} editor The editor to search in
 */
export const findDeepLink = ({
  editor,
  node,
}: {
  editor: Editor;
  node: DeepLink;
}): NodeEntry<DeepLinkPluginElement> | null | undefined =>
  find<DeepLinkPluginElement>(
    editor,
    (n: Node) =>
      n.type === DEEP_LINK_PLUGIN_ID &&
      n.variant === node.variant &&
      n.target === node.target &&
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      equals((n as DeepLinkPluginElement).context, node.context),
    { at: [] }
  );

export const isValidDeepLink: (payload: DeepLink) => boolean = (payload) => {
  return (
    ['viewer', 'worklist'].includes(payload.target) &&
    ['imageSlice', 'point', 'study'].includes(payload.variant)
  );
};

export const getComparisonField = (
  editor: Editor,
  comparisonHeadingKeywords: HeadingKeywords['comparison']
): NodeEntry | null | undefined => {
  if (editor == null || editor.children.length === 0) return;
  return Editor.next(editor, {
    at: [0],
    mode: 'highest',
    match: (node: Node) =>
      node.type === INLINE_BOOKMARK_PLUGIN_ID &&
      comparisonHeadingKeywords.includes(
        normalizeText(typeof node.name === 'string' ? node.name : '')
      ),
  });
};

export const populateComparisonStudyNodes = (
  editor: Editor | null | undefined,
  reviewedStudies: Map<string, ReviewedStudyByDate>,
  comparisonHeadingKeywords: HeadingKeywords['comparison'],
  currentStudySmid?: string | null,
  currentComparativeStudySmids?: readonly string[] | null
): void => {
  if (
    editor == null ||
    reviewedStudies == null ||
    reviewedStudies.size < 1 ||
    comparisonHeadingKeywords == null ||
    comparisonHeadingKeywords.length < 1
  )
    return;

  const comparisonField = getComparisonField(editor, comparisonHeadingKeywords);
  if (comparisonField == null) return;

  const firstNode = Editor.first(editor, comparisonField[1]);
  const firstNodePath = Editor.before(editor, firstNode[1]);
  const lastNode = Editor.last(editor, comparisonField[1]);
  const lastNodePath = Editor.after(editor, lastNode[1]);
  const emptyText = { text: '' } as const;
  const delimiterText = { text: ',' } as const;

  const allDeepLinkNodes = Array.from(
    Editor.nodes(editor, { at: comparisonField[1], match: isStudyDeepLink }) ?? []
  );

  /**
   * Removal logic (remove all deep link nodes that are not in the reviewed studies)
   */
  const nodePathsToRemove: Array<Path> = [];
  for (let i = 0; i < allDeepLinkNodes.length; i++) {
    const [dlNode, dlNodePath] = allDeepLinkNodes[i];
    if (!reviewedStudies.has(dlNode.context.studySmid)) {
      nodePathsToRemove.push(dlNodePath);
      // remove associated text nodes
      const associatedText = Editor.next(editor, {
        at: dlNodePath,
        match: (node: Node) => Text.isText(node),
      });
      if (associatedText != null) {
        if (i === allDeepLinkNodes.length - 1) {
          // clear the text node if it is associated with the last deep link node
          // in the comparison field to be removed
          Transforms.setNodes(editor, { text: '' }, { at: associatedText[1] });
        } else {
          nodePathsToRemove.push(associatedText[1]);
        }
      }
    }
  }
  while (nodePathsToRemove.length > 0) {
    const path = nodePathsToRemove.pop();
    if (path == null) continue;
    Transforms.removeNodes(editor, { at: path });
  }

  /**
   * Insertion logic (insert reviewed studies missing in the comparison field)
   */
  for (const [smid, study] of reviewedStudies) {
    // When the study in question is the primary study, do not add it.
    // TODO: This should be handled upstream by the logic that is providing the reviewed studies.
    //       If that is fixed, we could remove this check.
    if (smid === currentStudySmid) {
      logger.warn(
        '[populateComparisonStudyNodes] Skipping insertion of the primary study into the comparison field'
      );
      continue;
    }

    // When the study in question is not a current comparative study, do not add it.
    // This may occur for example if the comparative study was previously added, but the viewer is not open,
    // or the study was removed from the comparisons.
    if (!(currentComparativeStudySmids ?? []).includes(smid)) {
      logger.info(
        `[populateComparisonStudyNodes] Not inserting non-active comparative study ${smid}`
      );
      continue;
    }

    // check if the study is already in the comparison field
    const sameStudy = Editor.next(editor, {
      at: firstNodePath,
      match: (node: Node | DeepLink) => {
        if (!isStudyDeepLink(node)) return false;
        return smid === node.context.studySmid;
      },
    });
    if (sameStudy != null) {
      continue;
    }

    const deepLinkNode = generateComparisonDeepLinkNode(study);
    const nodeToInsert = {
      type: DEEP_LINK_PLUGIN_ID,
      ...deepLinkNode,
      children: [getTextNode(deepLinkNode, study)],
    } as const;

    // find the next deep link node that is older than the current item
    const nextDeepLinkNode = Editor.next(editor, {
      at: firstNodePath,
      match: (node: Node) => isOlderDeepLink(node, reviewedStudies, study.studyDate),
    });
    // find the previous deep link node that is newer than the current item
    const prevDeepLinkNode = Editor.previous(editor, {
      at: lastNodePath,
      // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'reverse' does not exist in type 'EditorPreviousOptions<any>'.
      reverse: true,
      match: (node: Node) => isNewerDeepLink(node, reviewedStudies, study.studyDate),
    });

    // if inserting as oldest one in the comparison field (last node)
    if (nextDeepLinkNode == null) {
      const textNode = Editor.previous(editor, {
        at: lastNodePath,
        match: (node: Node) => Text.isText(node),
      });
      if (textNode == null) continue;

      const targetPath = textNode[1];
      targetPath[targetPath.length - 1] += 1;
      const hasTrailingComma = textNode[0].text.trim().endsWith(',');
      if (prevDeepLinkNode == null || hasTrailingComma) {
        // if there are no deep link nodes in the comparison field or the previous text node ends with a comma
        Transforms.insertNodes(editor, [nodeToInsert, emptyText], { at: targetPath });
      } else {
        // if the previous text node does not end with a comma, insert a comma
        Transforms.insertNodes(editor, [delimiterText, nodeToInsert, emptyText], {
          at: targetPath,
        });
      }
      continue;
    }

    // if inserting into other positions in the comparison field
    const textNode = Editor.previous(editor, {
      at: nextDeepLinkNode[1],
      // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'reverse' does not exist in type 'EditorPreviousOptions<BaseText>'.
      reverse: true,
      match: (node: Node) => Text.isText(node),
    });
    if (textNode == null) continue;

    const targetPath = textNode[1];
    targetPath[targetPath.length - 1] += 1;
    Transforms.insertNodes(editor, [nodeToInsert, delimiterText], {
      at: targetPath,
    });
  }
};

export const getDeepLinkKey = (
  editor: Editor | null,
  element: DeepLinkPluginElement | null
): Key | null => {
  if (editor == null || element == null) return null;
  try {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'import("slate-react").Key' is not assignable to type 'React.Key'.
    return ReactEditor.findKey(editor, element);
  } catch (e: any) {
    logger.error('[getDeepLinkKey] Failed to get key for deep link element', e);
    return null;
  }
};
