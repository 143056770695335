import type { ListPluginElement, ListPluginPropertyOptions } from '../types';
import styled from 'styled-components';
import { LIST_VARIANTS } from '../constants';
import { forwardRef } from 'react';

type ListEditableProps = {
  pluginOptions: ListPluginPropertyOptions;
  element: ListPluginElement;
  children: React.ReactNode;
};

export const ListEditable = forwardRef<Element, ListEditableProps>(
  ({ pluginOptions: { pluginID }, element, children, ...rest }, ref) => {
    if (element.variant === LIST_VARIANTS.ol) {
      return (
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        <OrderedListComponent ref={ref} pluginID={pluginID} element={element} {...rest}>
          {children}
        </OrderedListComponent>
      );
    } else if (element.variant === LIST_VARIANTS.ul) {
      return (
        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        <UnorderedListComponent ref={ref} pluginID={pluginID} element={element} {...rest}>
          {children}
        </UnorderedListComponent>
      );
    } else {
      return null;
    }
  }
);

ListEditable.displayName = 'ListEditable';

const OrderedListComponent = styled.ol`
  margin: 0;
`;

export const OrderedListRenderer = OrderedListComponent;

const UnorderedListComponent = styled.ul`
  margin: 0;
`;

export const UnorderedListRenderer = UnorderedListComponent;
