import { Node } from 'domains/reporter/RichTextEditor/core';
import { NAMESPACES, useEventsListener, sendEvent } from 'modules/EventsManager';
import { Editor, Transforms } from '../../core';
import type { CreateEnhanceProvider } from '../../types';
import { selectNextBracket } from '../../utils/bracketNavigation';
import type { DeepLinkPluginPropertyOptions, DeepLink } from './types';
import { findDeepLink, insertDeepLink, isValidDeepLink } from './utils';
import { DEEP_LINK_VARIANT_TYPES } from './constants';
import { nanoid } from 'nanoid';
import { PAGE_TYPES } from 'utils/pageTypes';
import type { ComponentType } from 'react';
import type { SlateProps } from 'slate-react';

type ProviderComponentProps = Readonly<{
  editor: Editor;
  children: React.ReactNode;
  ignoreMergeFieldsInNavigation?: boolean;
}>;

/**
 * The provider component.
 *
 * Attaches an event handler when the editor is loaded, which listens for messages on the anatomic navigator broadcast channel.
 * @param {ProviderComponentProps} props - Provider component properties.
 */
const ProviderComponent = ({
  children,
  editor,
  ignoreMergeFieldsInNavigation,
  ...rest
}: ProviderComponentProps): React.ReactElement => {
  const editorHandler = handleCreateEvent(editor, ignoreMergeFieldsInNavigation);

  useEventsListener(NAMESPACES.DEEP_LINK, ({ payload: { type, payload } }) => {
    if (type === 'INSERT') {
      editorHandler(payload);
    }
  });

  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

/**
 * Creates the provider component.
 *
 * @param {DeepLinkPluginPropertyOptions} props - Plugin property options for section header
 */
export const enhanceProvider: CreateEnhanceProvider<DeepLinkPluginPropertyOptions> =
  ({ pluginID }) =>
  (Component: ComponentType<SlateProps>) =>
    function EnhanceProvider(props: SlateProps) {
      return (
        <ProviderComponent editor={props.editor}>
          <Component {...props} />
        </ProviderComponent>
      );
    };

/**
 * Event handler to create the deep link when requested
 *
 * Curryable.
 *
 * @param {Editor} editor - Editor, which will be updated by these events
 * @param {Object} payload - Data payload from the event.
 * @param {boolean} ignoreMergeFieldsInNavigation - whether merge fields should be included in navigation
 */
const handleCreateEvent =
  (editor: Editor, ignoreMergeFieldsInNavigation?: boolean) => (payload: DeepLink) => {
    if (!isValidDeepLink(payload)) {
      return;
    }

    const existingNodeEntry = findDeepLink({
      editor,
      node: payload,
    });

    if (!existingNodeEntry) {
      insertDeepLink({ editor, node: payload });

      if (payload.variant === DEEP_LINK_VARIANT_TYPES.IMAGE_SLICE) {
        sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
          type: 'showCrossTabNotification',
          payload: {
            toastKey: nanoid(),
            targetPageTypes: [PAGE_TYPES.VIEWER],
            severity: 'success',
            position: 'top-right',
            message: 'Bookmarked image inserted into report',
          },
        });
      }

      const nextNodeEntry = Editor.next<Node>(editor, { mode: 'lowest' });
      if (nextNodeEntry) {
        const [, nextNodePath] = nextNodeEntry;
        Transforms.select(editor, Editor.start(editor, nextNodePath));
      } else {
        // We should always find a node since the structure demands it, but in case we don't we select the next bracket.
        selectNextBracket(editor, { ignoreMergeFieldsInNavigation });
      }
    } else {
      // If we do find this concept in the report, set focus there.
      const [, existingPath] = existingNodeEntry;

      // Select the one after the existing node.
      const nextNodeEntry = Editor.next<Node>(editor, { at: existingPath });

      if (nextNodeEntry) {
        const [, nextNodePath] = nextNodeEntry;
        Transforms.select(editor, nextNodePath);
      } else {
        // We should always find a node since the structure demands it, but in case we don't we select the end.
        Transforms.select(editor, Editor.end(editor, []));
      }
    }
  };
