import { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { NotEditable } from '../../../components/NotEditable';
import type { DeepLink, DeepLinkPluginElement, DeepLinkPluginOptions } from '../types';
import { ReadonlyDeepLinkElement } from '.';
import { editableStudyDeepLinkState } from 'domains/reporter/Reporter/state';
import { useRecoilState } from 'recoil';
import { ReactEditor } from 'slate-react';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import type { TEditableState } from '../../../../Reporter/state';
import type { DeepLinkColors } from 'hooks/useDeepLinkColors';
import { Colors } from 'styles';
import {
  DEFAULT_DEEP_LINK_BACKGROUND_COLOR,
  DEFAULT_DEEP_LINK_BORDER,
  useDeepLinkColors,
} from 'hooks/useDeepLinkColors';
import { DEEP_LINK_VARIANT_TYPES } from '../constants';

export const DeepLinkStyles = css`
  pointer-events: auto;
  padding: 2px 4px;
  margin: 0 4px 0 0;
  white-space: nowrap;
  vertical-align: baseline;
  border: ${(props: DeepLinkElementProps) => props.colors?.border ?? DEFAULT_DEEP_LINK_BORDER};
  border-radius: 6px;
  background: ${(props: DeepLinkElementProps) =>
    props.colors?.backgroundColor ?? DEFAULT_DEEP_LINK_BACKGROUND_COLOR};
  z-index: ${(props: DeepLinkElementProps) => (props.selected === true ? 10 : 0)};
  color: ${(props: DeepLinkElementProps) =>
    props.selected === true ? Colors.blue6 : Colors.white};
  cursor: ${(props: DeepLinkElementProps) =>
    props.isClickable === true
      ? 'pointer'
      : props.studyDeepLinkState === 'editing'
        ? 'text'
        : 'default'};
  box-shadow: ${(props: DeepLinkElementProps) =>
    props.selected === true ? `0 0 0 1px ${Colors.blue6}` : 'none'};
`;

type DeepLinkRendererProps = Readonly<{
  children: React.ReactNode;
  element: DeepLinkPluginElement;
  pluginOptions?: DeepLinkPluginOptions;
}>;

export type DeepLinkElementProps = Readonly<
  DeepLinkRendererProps & {
    selected?: boolean;
    isClickable?: boolean;
    colors?: DeepLinkColors;
    studyDeepLinkState?: null | TEditableState;
    ['data-testid']?: string;
  }
>;

const getTestId = (node: DeepLink): string => {
  let id = `deepLink-${node.variant}`;
  switch (node.variant) {
    case DEEP_LINK_VARIANT_TYPES.STUDY:
      id += `-${node.context?.studySmid}`;
      break;
    case DEEP_LINK_VARIANT_TYPES.IMAGE_SLICE:
      id += `-${node.context?.seriesSmid}-${node.context?.frameSmid}-${node.context?.imageNumber}`;
      break;
    case DEEP_LINK_VARIANT_TYPES.POINT:
      id += `-${node.context?.seriesSmid}-${node.context?.label}`;
      break;
    default:
      return id;
  }
  return id;
};

const EditableComponent = styled.span`
  ${DeepLinkStyles}
  /* override cursor blinker in RichTextEditor so it displays above the bg color of the node */
  &.editable,
  &.editable * {
    caret-color: #ffffff !important;
  }
`;

export const DeepLinkElement = forwardRef<
  DeepLinkElementProps,
  (DeepLinkElementProps & HTMLElement) | typeof NotEditable
>(({ selected, element, children, pluginOptions, ...rest }, ref) => {
  const { children: _children, type, ...node } = element;
  const [{ editor }] = useSlateSingletonContext();
  const [editableStudyDeepLinks] = useRecoilState(editableStudyDeepLinkState);
  const colors = useDeepLinkColors(node);
  const deepLinkKey = useMemo(() => {
    if (editor == null || element == null) return null;
    try {
      return ReactEditor.findKey(editor, element);
    } catch (e: any) {
      return null;
    }
  }, [editor, element]);
  const studyDeepLinkState = useMemo<TEditableState | null>(() => {
    if (editableStudyDeepLinks == null || deepLinkKey == null) return null;
    if (
      !editableStudyDeepLinks.has(deepLinkKey) ||
      element.variant !== DEEP_LINK_VARIANT_TYPES.STUDY
    )
      return null;

    return editableStudyDeepLinks.get(deepLinkKey) ?? null;
  }, [deepLinkKey, element, editableStudyDeepLinks]);

  if (studyDeepLinkState === 'editing') {
    return (
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      <EditableComponent
        className="editable"
        data-testid={getTestId(node)}
        element={element}
        selected={selected}
        studyDeepLinkState={studyDeepLinkState}
        colors={colors}
        isClickable={false}
        {...rest}
      >
        {children}
      </EditableComponent>
    );
  } else {
    return (
      // @ts-expect-error [EN-7967] - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided.
      <ReadonlyDeepLinkElement
        data-testid={getTestId(node)}
        // @ts-expect-error [EN-7967] - TS2322 - Type '(element: DeepLinkPluginElement, editor: any) => void' is not assignable to type 'never'.
        onDeepLinkClick={pluginOptions?.onDeepLinkClick}
        // @ts-expect-error [EN-7967] - TS2322 - Type '(element: DeepLinkPluginElement, editor: any) => void' is not assignable to type 'never'.
        onDeepLinkEditClick={pluginOptions?.onDeepLinkEditClick}
        // @ts-expect-error [EN-7967] - TS2322 - Type 'boolean' is not assignable to type 'never'.
        showEditMenu={studyDeepLinkState === 'menuOpen'}
        // @ts-expect-error [EN-7967] - TS2322 - Type 'boolean' is not assignable to type 'never'.
        selected={selected}
        // @ts-expect-error [EN-7967] - TS2322 - Type 'DeepLinkPluginElement' is not assignable to type 'never'.
        element={element}
        // @ts-expect-error [EN-7967] - TS2322 - Type 'Readonly<Readonly<{ pluginName?: string; hotkey?: string; icon?: ComponentType<Record<any, any>>; }> & { component?: ComponentType<any>; ignoreMergeFieldsInNavigation?: boolean; isAutoFillComparisonEnabled?: boolean; onDeepLinkClick?: (element: DeepLinkPluginElement, editor: any) => void; onDeepLinkEditClick?: (elem...' is not assignable to type 'never'.
        pluginOptions={pluginOptions}
        // @ts-expect-error [EN-7967] - TS2322 - Type 'DeepLinkColors' is not assignable to type 'never'.
        colors={colors}
        ref={ref}
        {...rest}
      >
        {children}
      </ReadonlyDeepLinkElement>
    );
  }
});
DeepLinkElement.displayName = 'DeepLinkElement';

export const ReadonlyDeepLinkRenderer = forwardRef<DeepLinkRendererProps, typeof NotEditable>(
  (props, ref) => {
    // @ts-expect-error [EN-7967] - TS2322 - Type '{ ref: ForwardedRef<Readonly<{ children: ReactNode; element: DeepLinkPluginElement; pluginOptions?: Readonly<Readonly<{ pluginName?: string; hotkey?: string; icon?: ComponentType<Record<any, any>>; }> & { ...; }>; }>>; }' is not assignable to type 'Omit<StyledComponent<"span", any, { contentEditable: false; }, "contentEditable">, "ref">'.
    return <ReadonlyDeepLinkElement {...props} ref={ref} />;
  }
);
ReadonlyDeepLinkRenderer.displayName = 'ReadonlyDeepLinkRenderer';

export const DeepLinkRenderer = styled(DeepLinkElement)`
  white-space: pre-wrap;
`;
