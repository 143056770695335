import { atom, atomFamily, DefaultValue, selectorFamily, useRecoilValue } from 'recoil';
import type { RecoilState } from 'recoil';

import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';

import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useMatch } from 'react-router-dom';
import { PATH } from 'config/constants';

/**
 * Viewports that have been expanded (either full screen or split).
 *
 * It will be considered 'up'
 */

export const viewportUpIds: RecoilState<{
  [key: string]: Array<string> | null | undefined;
}> = atom<{
  [key: string]: Array<string> | null | undefined;
}>({
  key: 'viewer.dre.viewportUpIds',
  default: {},
  effects: [broadcastChannelSynchronizerEffect()],
});

export const viewportUpIdSelector: (
  windowId?: string | null | undefined
) => RecoilState<ReadonlyArray<string>> = selectorFamily({
  key: 'viewer.dre.viewportUpIdsSelector',
  get:
    (windowId?: string | null) =>
    ({ get }) => {
      if (windowId == null) {
        return [];
      }
      return get(viewportUpIds)[windowId] ?? [];
    },
  set:
    (windowId?: string | null) =>
    ({ set, get }, newValue: DefaultValue | ReadonlyArray<string>) => {
      if (windowId == null) {
        return;
      }

      const current = get(viewportUpIds);
      set(viewportUpIds, {
        ...current,
        [windowId]: newValue,
      });
    },
});

export const selectedThumbnailState: RecoilState<string | null | undefined> = atom({
  key: 'selectedThumbnail',
  default: null,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const baseViewerDataLoadingState: RecoilState<boolean> = atom({
  key: 'baseViewerDataLoadingState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const thumbnailBarVisible: (caseSmid: string) => RecoilState<boolean> = atomFamily({
  key: 'thumbnailBarVisibile',
  default: true,
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

export const toolBarVisible: RecoilState<boolean> = atom({
  key: 'toolBarVisible',
  default: true,
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

export function useThumbnailBarVisible(): boolean {
  const currentCaseSmid = useCurrentCaseId();
  return useRecoilValue(thumbnailBarVisible(currentCaseSmid ?? ''));
}

export function useSidebarVisible(): boolean {
  const showToolBar = useRecoilValue(toolBarVisible);
  const isViewer = useMatch(PATH.VIEWER) != null;
  return !isViewer || showToolBar;
}
