export const DEEP_LINK_VARIANT_TYPES: {
  IMAGE_SLICE: 'imageSlice';
  POINT: 'point';
  STUDY: 'study';
} = {
  IMAGE_SLICE: 'imageSlice',
  POINT: 'point',
  STUDY: 'study',
};

export const DEFAULT_STUDY_DATE_VALUE = -8.64e15; // earliest date in JS
export const MISSING_STUDY_DESCRIPTION = '<missing study description>';
export const MISSING_STUDY_DATE = '<missing study date>';
